import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
    Popover,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Hidden
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../common/HeaderTitle";
import Paper from "@mui/material/Paper";
import Pagination from "../common/Pagination";
import { ImageUploadService } from "./services/ImageUpload";
import Skeleton from "../common/Skeleton";
import { FileUploader } from "react-drag-drop-files";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageIcon from "@mui/icons-material/Image";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { commonActions } from "../../redux/features/common/commonSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { ROUTES } from "../../constants/routes";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import ModalUtility from "../../utils/modalUtility";
import CONSTANTS, { LIST_LIMIT, ROLE_MANAGEMENT_SYSTEM, IMAGE_UPLOAD } from "../../constants/constants";
import MUIDataTable from "mui-datatables";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CommonButton from "../common/Button";
import Timer from "../common/Timer";
import { imageUploadConfig } from "./config/imageUploadConfig";
import Utility from "../../utils/Utility";
import { commonInitialValue } from "../../interfaces/commonInterface";
import { newPermissionInitialValue } from "../../redux/features/newRoleBasedPermission/newPermissionSlice";



const ImageUpload = (props: any) => {

    const { showSnackbar, handleLoader } = props;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [t, i18n] = useTranslation();
    
    const fileTypes = ["JPG"];
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    // const timer = useAppSelector(state => state?.common?.timerValue);
    const dataAfterUploadRedux = useAppSelector((state) => state?.common?.productData) ?? commonInitialValue?.productData;
    const jsonDataAfterUploadRedux = useAppSelector((state) => state?.common?.productDataJson) ?? commonInitialValue?.productDataJson;
    const imagePagination = useAppSelector((state: any) => state?.common?.imagePagination) ?? commonInitialValue?.imagePagination;
    const rememberImageUploadParams = useAppSelector((state: any) => state?.common?.rememberImageUploadParams) ?? commonInitialValue?.rememberImageUploadParams;
    const selectedProduct = useAppSelector((state: any) => state?.common?.selectedProduct) ?? commonInitialValue?.selectedProduct;
    const permission_list = useAppSelector((state) => state.newPermission.newPermissionDetails.modules?.seller) ?? newPermissionInitialValue.newPermissionDetails.modules?.seller;
    const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType) ?? null;
    const updatedTimerValue = useAppSelector(state => state.common.timerValue) ?? commonInitialValue?.timerValue;
    const count: any = imagePagination?.count ?? 0;
    const apiData: any[][] = [];
    const productManagementPermission = permission_list?.find((item: any) => item.key === "merchandise_management");
    const utility = new Utility;
    const [file, setFile] = useState<any>(null);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.LIMIT_15 : perPageLimit);
    const [search, setSearch] = useState<string>("");
    const [products, setProducts] = useState<any>();
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [images, setImages] = useState<any>();
    const [singleImageListData, setSingleImageListData] = useState<any>({});
    const [cainzProductSelected, setCainzProductSelected] = useState<any>(null);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showSkeletonInImageBox, setShowSkeletonInImageBox] = useState(true);
    const [loadingInImage, setLoadingInImage] = useState<any>([]);
    const [cainzProductFeaturedImg, setCainzProductFeatureImg] = useState<any>(null);
    const [popoverSkeleton, setPopoverSkeleton] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLButtonElement | null>(null);
    const [dataAfterUpload, setDataAfterUpload] = useState<any>([]);
    const [jsonDataAfterUpload, setJsonDataAfterUpload] = useState<any>([]);
    const [sizeModalOpen, setSizeModalOpen] = useState(false);
    const [typeModalOpen, setTypeModalOpen] = useState(false);
    const [imgMustModalOpen, setImgMustModalOpen] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [selectedProductCode, setSelectedProductCode] = useState("");
    const [colFilter, setColFilter] = useState<string>("");
    const [direction, setDirection] = useState<string>("");
    const [colName, setColName] = useState<string>("");
    const [startTimer, setStartTimer] = useState<boolean>();
    const [disable, setDisable] = useState<boolean>(false);
    const [timerModalOpen, setTimerModalOpen] = useState<boolean>(false);
    const [operation, setOperation] = useState<boolean>(false);
    const [dialogBox, setDialogBox] = useState<boolean>(true);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [isSortingChange,setIsSortingChange]=useState<boolean>(false);
    const [dragIndex, setDragIndex] = useState<any>(null);
    const [dragImageUrl, setDragImageUrl] = useState<any>(null);
    const [isBackdrop,setIsBackdrop]=useState<boolean>(false);
    const [dragBlob,setDragBlob]=useState<any>(null);
    const [dropBlob,setDropBlob]=useState<any>(null);
    const imageListFilter = useAppSelector((state:any) => state?.common?.imageListFilter);
    const [imageStatusFilter, setImageStatusFilter] = useState<any>(imageListFilter?.imageStatus ?? "none");
    const [flagFilter, setFlagFilter] = useState<any>(imageListFilter?.flag ?? "none");

    useEffect(() => {
        if (submitClicked) {
            setSubmitClicked(false);
            productData(currentPage, limit, false, true);
        }
    }, [submitClicked]);

    useEffect(() => {
        productData(firstPage, limit);
    }, [imageStatusFilter, flagFilter]);

    useEffect(() => {
        if(imagePagination?.count != 0){
            if(dataAfterUploadRedux.length > 0 ) {
                if(updatedTimerValue){
                    setStartTimer(true);
                }
            } else {
                setStartTimer(false);
                dispatch(commonActions.SAVE_TIMER_VALUE(null));
            }
        }else{
            if(dataAfterUploadRedux.length<=0){
                dispatch(commonActions.SAVE_TIMER_VALUE(null));
            }
        }
    }, [dataAfterUploadRedux]);

    useEffect(() => {
        if (count != 0) {
            setJsonDataAfterUpload(jsonDataAfterUploadRedux);
            setDataAfterUpload(dataAfterUploadRedux);
        }

    }, [count]);

    useEffect(() => {
        if (operation) {
            setStartTimer(false);
            setDisable(true);
            setTimerModalOpen(true);
            setDialogBox(false);
            dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
            dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));
            // dispatch(commonActions.SAVE_TIMER_VALUE({minutes: imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN, seconds: imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC }));
            dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": 0, "pagination": currentPage }));
            dispatch(commonActions.SAVE_TIMER_VALUE(null));
            dispatch(commonActions.IMAGE_LIST_FILTER({"flag": "none", "imageStatus": "none"}));
        }
    }, [operation]);


    useEffect(() => {
        if (isSortingChange) {         
            productData(firstPage, limit);

        }
    }, [isSortingChange]);

    const handleBeforeUnload = (event: any) => {
        if (dataAfterUpload?.length > 0) {
            dispatch(commonActions.SAVE_BROWSER_DIALOG_BOX_TIME(new Date().getTime()));
            event.preventDefault();
            event.returnValue = "";
            dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": 0, "pagination": currentPage }));
        }
    };

    useEffect(() => {
        if (dialogBox) {
            const handleLocationChange = () => {
                if (location.pathname !== ROUTES.IMAGE_UPLOAD) {
                    if (dataAfterUpload?.length > 0) {
                        dispatch(commonActions.SAVE_BROWSER_DIALOG_BOX_TIME(new Date().getTime()));
                        const confirmMessage = t("imageupload.Batch_reflection_button_has_not_been_clicked");
                        if (window.confirm(confirmMessage)) {
                            dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
                            dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));
                            dispatch(commonActions.SAVE_TIMER_VALUE(null));
                            // dispatch(commonActions.SAVE_TIMER_VALUE({minutes: imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN, seconds: imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC }));
                            dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": 0, "pagination": currentPage }));
                        } else {
                            dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": count + 1, "pagination": imagePagination?.pagination }));
                            navigate(ROUTES.IMAGE_UPLOAD);
                        }
                    }
                }
            };

            window.addEventListener("beforeunload", handleBeforeUnload);
            const unlisten = () => {
                handleLocationChange();
            };
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
                unlisten();
            };
        }
    }, [location, dataAfterUpload, dialogBox]);

    /**
 *
 * @param currentPage - request currentPage for pagination
 * @param limit - request limit for pagination
 * @param isNavigated - tell if user have try to change page or not
 * @param refreshSelectedProduct - tell is selected product need to refresh
 * @returns product list data
 * @description - Api call for product list 
 */
    const productData = (CurrentPage: number, Limit: number, isNavigated = false, refreshSelectedProduct = false) => {
        const payload = {};
        const isHitDummyURL = false;
        setIsSearchChange(false);
        setIsSortingChange(false);
        setShowSkeleton(true);
        new ImageUploadService(payload, isHitDummyURL)
            .imageList(CurrentPage, isNavigated ? rememberImageUploadParams?.search.trim() : search.trim(), Limit, isNavigated ? rememberImageUploadParams?.colFilter :colFilter, isNavigated ? rememberImageUploadParams?.direction :direction, imageStatusFilter, flagFilter)
            .then((res) => {
                setShowSkeleton(false);
                setShowSkeletonInImageBox(false);
                setProducts(res);               
                !isNavigated ? dispatch(commonActions.SET_IMAGE_UPLOAD_PARAM({ "search": search, "pageNumber": CurrentPage ,"colFilter":colFilter,"direction":direction ,"colName":colName})):null;
                !refreshSelectedProduct ? productImagesData(res?.bulkProductLogs, isNavigated, true)
                    : productImagesData(res?.bulkProductLogs, isNavigated);
                setCurrentPage(CurrentPage);
                setLimit(Limit);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
            })
            .catch((err: any) => {
                showSnackbar(err?.message);
                setShowSkeleton(false);
            });
    };

    /**
 *
 * @param isNavigated - tell if user have try to change page or not
 * @param pageChange - tell if page is changed
 * @description - Set data for selected product
 */
    const productImagesData = (imageData: any, isNavigated = false, pageChange = false) => {
        const tempImagesData: { productId: string; productName: string; data: any }[] = [];
        imageData?.map((item: any) => {
            tempImagesData?.push({
                productId: item?.cainzProductCode,
                productName: item?.productName,
                data: {
                    featureImage: item?.image?.featured,
                    productImage: item?.image?.others,

                },
            });
        });

        setImages(tempImagesData);
        const tempImages: { productId: string; productName: string; data: any }[] = [];
        tempImages?.push({
            productId: imageData[0]?.cainzProductCode,
            productName: imageData[0]?.productName,
            data: {
                featureImage: imageData[0]?.image?.featured,
                productImage: imageData[0]?.image?.others,
            },
        });
        setCainzProductSelected(tempImages);
        if (imageData.length > 0) {
            if(imagePagination?.count != 0){              
                handleProductImages(!pageChange ? selectedProduct : imageData[0]?.id, isNavigated);
            }else{
                handleProductImages(selectedProductCode && !pageChange ? selectedProductCode : imageData[0]?.id, isNavigated);
            }
        }
        else {
            setSingleImageListData({});
        }
    };

    useEffect(()=>{
        if (dataAfterUploadRedux.length > 0 && imagePagination?.count != 0) {
            setSearch(rememberImageUploadParams?.search);
            setColFilter(rememberImageUploadParams?.colFilter);
            setColName(rememberImageUploadParams?.colName);
            setDirection(rememberImageUploadParams?.direction);
            productData(rememberImageUploadParams?.pageNumber, limit, true,true);
        } else {
            dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
            dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));
            productData(firstPage, limit);
        }
    },[]);

    const onsearchApicall = () => {
        if(isSearchChange){
            productData(firstPage, limit);
        }

    };  
    

    const propItem = [
        onsearchApicall, search
    ];
    lazyTypingUtils(propItem);

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            productData(currentPage, limit);
        }
    };

    const handleLiveImages = ((event: any, productId: any, image: any) => {
        setPopoverSkeleton(true);
        // setLiveImagesOpen(true);
        let tempSelectedImgData: any = null;
        tempSelectedImgData = images?.filter(
            (imageItem: any) =>
                imageItem?.productId == productId
        );
        setCainzProductFeatureImg(tempSelectedImgData[0]?.data?.featureImage);
        setFile(image);
        const tempSelectedImgOtherData: any = [];
        if (tempSelectedImgData !== undefined && tempSelectedImgData !== null && tempSelectedImgData[0]?.data?.featureImage?.length > 0) {
            tempSelectedImgData[0]?.data?.productImage?.map((item: any, index: any) => {
                const payload = {};
                const isHitDummyURL = false;
                new ImageUploadService(payload, isHitDummyURL)
                    .checkImageStatus(item)
                    .then((response) => {
                        if (response?.status === 200) {
                            tempSelectedImgOtherData.push(item);
                            if (index == 4) {
                                setPopoverSkeleton(false);
                                setCainzProductSelected(tempSelectedImgOtherData);
                            }
                        }
                        else {
                            setPopoverSkeleton(false);
                            setCainzProductSelected(tempSelectedImgOtherData);
                        }
                    })
                    .catch((err: any) => {
                        showSnackbar(err?.message);
                    });

            });
            setAnchorEl(event.currentTarget);
        }

    });
    const handleLiveImagesCloseModal = () => {
        setAnchorEl(null);
        // setLiveImagesOpen(false);
    };

    /**
 *
 * @param productID - request productID for single producct list
 * @param isNavigated - tell if user have try to change page or not
 * @returns single product list data
 * @description - Api call for single products list
 */
    const handleProductImages = (productID: any, isNavigated = false) => {
        dispatch(commonActions.SET_SELECTED_PRODUCT(productID));
        setSelectedProductCode(productID);
        dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": productID, "count": count, "pagination": currentPage }));
        const loader = new Array(6).fill(false);
        setLoadingInImage(loader);
        setShowSkeletonInImageBox(true);
        if (productID !== undefined && productID !== "") {
            const payload = {};
            const isHitDummyURL = false;
            new ImageUploadService(payload, isHitDummyURL)
                .singleImageList(productID)
                .then((res) => {
                    updateProductImage(res, loader, isNavigated);
                })
                .catch((err: any) => {
                    showSnackbar(err?.message);
                });
        }
    };

    /**
 *
 * @param res - required res to update the image
 * @description - update the images of product as required
 */
    const updateProductImage = (res: any, loader: any, isNavigated = false) => {
        let productData: any = [...dataAfterUpload];
        if (isNavigated) {
            productData = [...dataAfterUploadRedux];
        }
        const filterproductData: any = productData?.filter((item: any) => item.fileName.split("_")[0] === res.cainzProductCode);
        const otherData = new Array(6).fill({ image: "", type: "" });

        if (filterproductData.length > 0) {
            filterproductData.map((item: any) => {
                const arrayindex = item?.fileName?.split("_0")[1].split(".")[0];
                otherData[parseInt(arrayindex) - 1] = {
                    image: item.file,
                    type: item.imageType,
                    uploading: item?.uploading
                };
                const tempImageData = {
                    productCode: res.cainzProductCode,
                    productName: res.productName,
                    data: {
                        productImage: otherData,
                    },
                };
                setSingleImageListData(tempImageData);
                setShowSkeletonInImageBox(false);

            });
        } else {
            loader.fill(true, 0, (res?.image?.length));
            setLoadingInImage(loader);
            res?.image.map((item: any, index: any) => {
                const arrayindex = item?.url?.split("_0")[1].split(".jpg")[0];
                const payload = {};
                const isHitDummyURL = false;
                new ImageUploadService(payload, isHitDummyURL)
                    .checkImageStatus(item?.url)
                    .then((response) => {
                        otherData[parseInt(arrayindex) - 1] = {
                            image: response?.status === 200 ? item?.url : "",
                            // type:item?.type == 0 ? 0 : 2,
                            type: item?.type == IMAGE_UPLOAD.IMGX_TYPE ? IMAGE_UPLOAD.IMGX_TYPE : item?.type == IMAGE_UPLOAD.BULK_IAMGE_UPLOAD_TYPE ? IMAGE_UPLOAD.BULK_IAMGE_UPLOAD_TYPE : IMAGE_UPLOAD.S3_TYPE,
                            // type:item?.url?.includes("imgix")?0:2,
                            uploading: item?.type == IMAGE_UPLOAD.IMGX_TYPE ? false : true
                        };
                        // const loader =[...loadingInImage];

                        setData();
                        // setLoadingInImage(loader);
                        // index==(res?.image?.length-1) ? 
                        // setTimeout(()=>{
                        // loader[index]=false;   
                        loader[arrayindex - 1] = false;
                        setLoadingInImage(loader);
                        // },1000);
                        // :null;                     
                    })
                    .catch((err: any) => {
                        loader[arrayindex - 1] = false;
                        setLoadingInImage(loader);
                        showSnackbar(err?.message);
                    });
            });
        }

        const setData = () => {
            const tempImageData = {
                productCode: res.cainzProductCode,
                productName: res.productName,
                data: {
                    productImage: otherData,
                },
            };
            setSingleImageListData(tempImageData);
            setShowSkeletonInImageBox(false);

        };

    };

    // file upload api
    const handleFileUpload = (e: any, productCode: any, productImageNo: any,fileUpload:any,setBlob:any) => {
        if(e) {
            setStartTimer(true);
            let currentDateTime = new Date().getTime();
            currentDateTime = Math.floor((currentDateTime/1000));
            const timerInterval = utility.toSeconds(0, Number(imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN), Number(imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC));
            const totalTimerValue = timerInterval + currentDateTime;
            if(!updatedTimerValue || !startTimer) {
                dispatch(commonActions.SAVE_TIMER_VALUE(totalTimerValue));
            }
        } else {
            setStartTimer(false);
            dispatch(commonActions.SAVE_TIMER_VALUE(null));
        }
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            const loader = [...loadingInImage];
            if(fileUpload){
                loader[productImageNo-1]=true;
                setLoadingInImage(loader);
                setIsBackdrop(true);
            }else{
                setDragIndex(null);
                setBlob(null);
            } 
            const file = e;
            const renamedFile = new File([file], `${productCode}_0${productImageNo}.jpg`, { type: file.type });
            const payload = { file: renamedFile };
            const isHitDummyURL = false;
            new ImageUploadService(payload, isHitDummyURL)
                .uploadImage()
                .then((res) => {
                // dispatch(commonActions.IS_RELOAD(true));
                    showImageUpload(res, productCode, productImageNo);
                    dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": imagePagination?.count, "pagination": currentPage }));
                    // setLoadingInImage(loader);
                    // loader[productImageNo - 1] = false;
                    setLoadingInImage((prevState:any)=>{
                        prevState[productImageNo-1]=false;
                        const isImageUploading = prevState.some((item:any)=>{
                            return(item == true);
                        });
                        !isImageUploading?
                            setIsBackdrop(false):
                            null;
                        return prevState;
                    }); 
                })
                .catch((err: any) => {
                    showSnackbar(err?.message ? err?.message: t("imageupload.load_img_error"));
                    setIsBackdrop(false);             
                    const loader = new Array(6).fill(false);
                    setLoadingInImage(loader);
                    setDragBlob(null);
                    setDropBlob(null);
                });
        }
    };

    const handleDragStart = (index:any,item:any) => {
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            setDragIndex(index);
            setDragImageUrl(item);
        }
    };

    const handleDragOver = (e:any)=>{
        if(dragIndex== null){
            e.dataTransfer.dropEffect = "none";
        }     
    };

    useEffect(()=>{
        if(dragBlob?.file && dropBlob?.file){
            handleFileUpload(dragBlob.file,dragBlob.productCode,dragBlob.index,false,setDropBlob);
            handleFileUpload(dropBlob.file,dropBlob.productCode,dropBlob.index,false,setDragBlob);
        }else{
            setDragIndex(null);
        }
    },[dragBlob,dropBlob]);

    const createFileObject =  (url:any,index:any,productCode:any,setBlob:any) => {
        const payload = {};
        const isHitDummyURL = false;
        new ImageUploadService(payload, isHitDummyURL)
            .createFileObjectFromUrl(url)
            .then((response) => {
                const blob = new Blob([response.data], { type: "image/jpeg" });
                const file = new File([blob], "image.jpg",{ type:  blob.type });
                setBlob({"file": file,"productCode":productCode, "index":index+1});                
            })
            .catch((err: any) => {
                showSnackbar(t("imageupload.load_img_error"));
                setIsBackdrop(false);             
                const loader = new Array(6).fill(false);
                setLoadingInImage(loader);
                setDragBlob(null);
                setDragIndex(null);
                setDropBlob(null);
            });
    };

    const handleDrop = (dropIndex:any,dropImageUrl:any,productCode:any) => {
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            if(dragIndex != null && dropIndex != dragIndex){
                setIsBackdrop(true);
                createFileObject(dragImageUrl?.image,dropIndex,productCode,setDropBlob);
                createFileObject(dropImageUrl?.image,dragIndex,productCode,setDragBlob);
                const loader=[...loadingInImage];
                loader[dragIndex]=true;
                loader[dropIndex]=true;
                setLoadingInImage(loader);          
            }else{
                setDragIndex(null);
            }   
        }  else{
            setDragIndex(null);
        }  
    };

    const showImageUpload = (res: any, productCode: any, productImageNo: any) => {
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            const tempdata: any = { ...singleImageListData };
            const visibleImagesArray: any = tempdata.data.productImage?.filter((item: any) => item.image !== "");
            if (visibleImagesArray?.length > 1 || res != "") {

                tempdata.data.productImage[productImageNo - 1] = { image: res, type: 1 };
                setSingleImageListData(tempdata);
                const imageArray: any = [...jsonDataAfterUpload];
                const productData: any = [...dataAfterUpload];
                const filterImageArr: any = imageArray?.filter((item: any) => item.fileName.split("_")[0] !== productCode);
                const filterProductData: any = productData?.filter((item: any) => item.fileName.split("_")[0] !== productCode);
                tempdata?.data?.productImage.map((item: any, index: any) => {
                    if (item?.image !== "") {
                        const productJson = {
                            fileName: `${productCode}_0${index + 1}.jpg`,
                            file: item?.image,
                            imageType: item?.type,
                            uploading: item?.uploading
                        };
                        filterProductData.push(productJson);
                    }
                    if (item?.image !== "") {
                        const ImageJson = {
                            fileName: `${productCode}_0${index + 1}.jpg`,
                            imageType: item?.type
                        };
                        filterImageArr.push(ImageJson);
                    }
                });
                setJsonDataAfterUpload(filterImageArr);
                dispatch(commonActions.PRODUCT_UPLOAD_JSON(filterImageArr));
                setDataAfterUpload(filterProductData);
                dispatch(commonActions.IS_PRODUCT_UPLOAD(filterProductData));
                dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": imagePagination?.count, "pagination": currentPage }));
            } else {
                setImgMustModalOpen(true);
            }
        }
    };

    //submit json api
    const handleSubmit = () => {
        handleLoader(true);
        setShowSkeleton(true);
        // dispatch(commonActions.IS_RELOAD(false));
        const payload = jsonDataAfterUpload;
        const isHitDummyURL = false;
        new ImageUploadService(payload, isHitDummyURL)
            .imageCollectionSubmit()
            .then((res) => {
                setShowSkeleton(false);
                // window.removeEventListener("beforeunload", handleBeforeUnload);
                dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
                dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));
                // dispatch(commonActions.SAVE_TIMER_VALUE({minutes: imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN, seconds: imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC }));
                dispatch(commonActions.IMAGE_PAGINATION_DATA({ "productId": imagePagination.productId, "count": 0, "pagination": currentPage }));
                setJsonDataAfterUpload([]);
                setDataAfterUpload([]);
                setSubmitClicked(true);
                setStartTimer(false);
                dispatch(commonActions.SAVE_TIMER_VALUE(null));
                handleLoader(false);
                showSnackbar(res, true);
            })
            .catch((err) => {
                setShowSkeleton(false);
                handleLoader(false);
                showSnackbar(err?.message);
            });
    };
    const fileSizeEcxeed = () => {
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            setSizeModalOpen(true);
        }
    };
    const fileTypeChange = () => {
        if (loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED) {
            setTypeModalOpen(true);
        }
    };
    const handleInsPopoverOpen = (event: any) => {
        setAnchorElPopover(event.currentTarget);
    };

    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    const handleSorting = (changedColumn: any) => {
        setIsSortingChange(true);
        if (colName != changedColumn) {
            setDirection("asc");

        } else {
            setDirection(direction == "asc" ? "desc" : "asc");
        }

        setColName(changedColumn);
        const columnName = changedColumn == "Product Code" || changedColumn == "商品コード" ? "shukkamoto_mng_hinban" :
            changedColumn == "Cainz Product Code" || changedColumn == "カインズ商品コード" ? "daihyo_jan_cd" :
                changedColumn == "Product Name" || changedColumn == "商品名" ? "prd_nm_kj" :
                    "";
        setColFilter(columnName);

    };

    const columns = [
        {
            name: t("imageupload.sno"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("imageupload.featured_image"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("imageupload.product_code"),
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e) => handleSorting(data.name)} >
                                {colFilter != "shukkamoto_mng_hinban" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: t("imageupload.cainz_product_code"),
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e) => handleSorting(data.name)} >
                                {colFilter != "daihyo_jan_cd" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },

        {
            name: t("imageupload.product_name"),
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e) => handleSorting(data.name)} >
                                {colFilter != "prd_nm_kj" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ? <ArrowUpwardIcon className="sortingIconPosition" /> : <ArrowDownwardIcon className="sortingIconPosition" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: t("imageupload.public_flag"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("imageupload.live_images"),
            options: {
                filter: false,
                sort: false,
            },
        },

    ];

    const tableFeaturedImage = (item: any) => {
        return (
            <>
                <Box className="image-upload oms_table_box">

                    <Typography></Typography>

                    {item ?
                        (
                            <>
                                {
                                    <img src={item} />
                                }
                            </>
                        ) : (
                            <ImageIcon className="featuredImage" />
                        )}
                </Box>
            </>
        );
    };


    const tableProductName = (item: any) => {
        return (
            <>
                <Box onClick={() => handleProductImages(item?.id)} className="Order_id_link">
                    {item?.productName}

                </Box>
            </>
        );
    };
    const tableLiveImages = (item: any) => {
        return (
            <>
                <Box onClick={(e) => handleLiveImages(e, item?.cainzProductCode, item?.image?.featured)} className="Order_id_link">
                    {t("imageupload.live_images")}
                </Box>
            </>
        );
    };


    const apiTableData = () => {
        {
            products?.bulkProductLogs?.map((item: any, index: number) => {
                const LoadingImage = images?.filter(
                    (imageItem: any) =>
                        imageItem?.productId == item?.cainzProductCode
                );
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    tableFeaturedImage(LoadingImage[0]?.data?.featureImage),
                    item?.productCode,
                    item?.cainzProductCode,
                    tableProductName(item),
                    item?.onlineFlag == 0 ? t("imageupload.private") : t("imageupload.public"),
                    tableLiveImages(item)


                );
            });
        }
    };
    apiTableData();

    const handleFlag = (event: any) => {
        setFlagFilter(event.target.value);
    };

    const handleImageStatus = (event: any) => {
        setImageStatusFilter(event.target.value);
    };

    return (
        <>
            <Popover
                // id={id}
                open={Boolean(anchorElPopover)}
                anchorEl={anchorElPopover}
                onClose={handleInsPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                className="instruction_popover"

            >
                <Box className="instruction_popover_box">
                    <Typography sx={{ pb: 2 }}>{t("imageupload.instruction_no_1")}</Typography>
                    <Typography>{t("imageupload.instruction_no_2")}</Typography>
                    <Typography>{t("imageupload.instruction_no_3")}</Typography>
                    <Typography>{t("imageupload.instruction_no_4")}</Typography>
                    <Typography>{t("imageupload.instruction_no_5")}</Typography>
                    <Typography sx={{ pb: 2 }}>{t("imageupload.instruction_no_6")}</Typography>
                    <Typography>{t("imageupload.instruction_no_7")}</Typography>
                </Box>
            </Popover>
            <Popover
                // open={liveImagesOpen}
                onClose={handleLiveImagesCloseModal}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                className="Image_upload_popover"
            >

                <Paper elevation={3} sx={{ padding: "16px" }} className="image_modal" >
                    {popoverSkeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="5"
                            my={0}
                        />
                    ) : (
                        <Grid container spacing={1} sx={{ py: 5 }} >
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Paper elevation={3} sx={{ padding: "16px" }} className="upload_image_container modal_main_image">
                                    <Box className="image-upload">
                                        <img src={file ? file : ""} className="modalMainImageProductSize" />
                                    </Box>
                                </Paper>
                            </Grid>
                            {/* Featured Image Starts */}
                            <Grid item xs={2} sm={2} md={2} lg={2} >
                                <Paper elevation={3} sx={{ padding: "16px" }} className="upload_image_container bottom_img_container"  >
                                    <Box className="image-upload">
                                        <img src={cainzProductFeaturedImg ? cainzProductFeaturedImg : ""} onClick={() => setFile(cainzProductFeaturedImg)} className="modalImageProductSize" />

                                    </Box>
                                </Paper>
                            </Grid>
                            {/* Featured Image Ends */}

                            {/* Other Image Starts */}
                            {cainzProductSelected?.length > 0 ? cainzProductSelected?.map((item: any, index: any) => {
                                return (
                                    <Grid item xs={2} sm={2} md={2} lg={2} key={index}>
                                        <Paper elevation={3} sx={{ padding: "16px" }} className="upload_image_container bottom_img_container"   >
                                            <Box>

                                                <img src={item} className="modalImageProductSize" onClick={() => setFile(item)} />

                                            </Box>
                                        </Paper>
                                    </Grid>
                                );
                            }) : ""}
                            {/* Other Image Ends */}
                        </Grid>
                    )}
                </Paper>
            </Popover>
            <Box className="main">



                <Container className="image_list_table first_table_cell_small_width">
                    {isBackdrop ?
                        <Backdrop 
                            sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}>                       
                        </Backdrop>
                        :null}
                    <>
                        <Grid container spacing={0} sx={{ py: 5 }} className="image-upload-search">
                            <Grid item xs={12} sm={12} md={12} lg={12} className="heading_grid">
                                <HeaderTitle title={t("imageupload.image_list")} />
                                <Box component="span" className="image_upload_instruction_icon_box">
                                    <Typography className="about_uploading_image">{t("imageupload.about_uploading_images")}
                                        <InfoRoundedIcon
                                            onClick={handleInsPopoverOpen}
                                            color="primary"
                                            className="image_upload_instruction_icon"

                                        />
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ py:4}} className="image-upload-search">
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{height: "50px"}}>
                                {
                                    jsonDataAfterUpload.length == 0 ? null : (
                                        <Hidden only={["xs"]}>
                                            {
                                                startTimer ? (
                                                    <Box className="timer-align">
                                                        <Timer  minutes={imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN} seconds={imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC} operation={setOperation} message1={t("imageupload.timer_msg_1")} message2={t("imageupload.timer_msg_2")} />
                                                    </Box>
                                                ) : null
                                                
                                            }
                                        </Hidden>
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3} >
                                <TextField
                                    id="input-with-icon-textfield"
                                    label={t("imageupload.search_place_holder")}
                                    onChange={handleSearchChange}
                                    onKeyUp={handleKeyPress}
                                    value={search}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={1} lg={4}></Grid>
                            <Grid item xs={12} sm={2} md={3} lg={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">{t("imageupload.public_flag")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={flagFilter}
                                        label={t("imageupload.public_flag")}
                                        onChange={handleFlag}
                                        fullWidth
                                    >
                                        <MenuItem value={"false"}>{t("imageupload.private")}</MenuItem>
                                        <MenuItem value={"true"}>{t("imageupload.public")}</MenuItem>
                                        <MenuItem value={"none"} >{t("imageupload.all")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={3} lg={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">{t("imageupload.image_status")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={imageStatusFilter}
                                        label={t("imageupload.image_status")}
                                        onChange={handleImageStatus}
                                        fullWidth
                                    >
                                        <MenuItem value="false">{t("imageupload.not_set")}</MenuItem>
                                        <MenuItem value="none">{t("imageupload.all")}</MenuItem> 
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={1} className="display-flex-with-space">
                                {
                                    jsonDataAfterUpload.length == 0 ? null : (
                                        <Hidden only={["sm", "md", "lg", "xl"]}>
                                            {
                                                startTimer ? (
                                                    <Box sx={{marginRight:"15px"}}>
                                                        <Timer  minutes={imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN} seconds={imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC} operation={setOperation} message1={t("imageupload.timer_msg_1")} message2={t("imageupload.timer_msg_2")} />
                                                    </Box>
                                                ) : null
                                            }
                                        </Hidden>
                                    )
                                }
                                <Box className="image_upload_save_btn">
                                    <CommonButton route={window.location.pathname} functionToExecute={handleSubmit} className="" btnName={t("imageupload.save")} disableCondition={jsonDataAfterUpload.length == 0 ? true : (disable ? true : false)} />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="image_upload_container">
                            <Grid item xs={7} sm={7} md={7} lg={7} className="image_data_inner_container">
                                {showSkeleton ? (
                                    <Skeleton
                                        varient="rectangular"
                                        width={0}
                                        height={60}
                                        count="7"
                                        my={0}
                                    />
                                ) : (
                                    <Container className="common_table image_upload_table">
                                        <MUIDataTable
                                            title={""}
                                            columns={columns}
                                            data={apiData}
                                            options={{
                                                pagination: false,
                                                print: false,
                                                download: false,
                                                filter: false,
                                                search: false,
                                                viewColumns: false,
                                                selectableRowsHideCheckboxes: true,
                                                textLabels: {
                                                    body: {
                                                        noMatch: t("table_text.no_match").toString(),
                                                    },
                                                    viewColumns: {
                                                        title: t("table_text.show_columns").toString(),
                                                        titleAria: "Show/Hide Table Columns",
                                                    },
                                                    toolbar: {
                                                        search: t("table_text.search").toString(),
                                                        viewColumns: t("table_text.view_columns").toString(),
                                                    },
                                                },
                                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                                responsive: "standard",
                                            }}

                                        />
                                    </Container>
                                )}
                                <Container>
                                    <Pagination
                                        totalCount={totalCount}
                                        currentPage={currentPage}
                                        pageChange={productData}
                                        totalPage={totalPages}
                                        rowsPerPage={limit}
                                        rowsPerPageChange={setLimit}
                                    />
                                </Container>
                            </Grid>


                            <Grid item xs={5} sm={5} md={5} lg={5} className="image_upload_box_container" >

                                <Paper elevation={3} sx={{ padding: "16px" }} className="image_upload_box"  >
                                    {showSkeletonInImageBox ? (
                                        <Skeleton
                                            varient="rectangular"
                                            width={0}
                                            height={60}
                                            count="10"
                                            my={0}


                                        />
                                    ) : (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Typography variant="h6" className="image_uplaod_product_name text_wrap_data">{singleImageListData ? singleImageListData?.productName : ""}</Typography>
                                                </Grid>
                                            </Grid>


                                            <Grid container spacing={1} sx={{ py: 5 }}>

                                                {singleImageListData?.data?.productImage ? singleImageListData?.data?.productImage?.map((item: any, index: any) => {
                                                    return (
                                                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                                            <Paper elevation={3} sx={{ padding: "16px" }} className="upload_image_container"  >
                                                                {item.image != "" ?
                                                                    <CancelIcon className={`cancel_upload_img ${loggedInUserType == CONSTANTS.USER_TYPE_ADMIN && productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? "disable_cancel_upload_img" : ""}`} onClick={() => showImageUpload("", singleImageListData?.productCode, index + 1)} />
                                                                    : ""
                                                                }

                                                                <Box className="image-upload" onDragStart={()=>handleDragStart(index,item)} onDragOver={handleDragOver} onDrop={()=>handleDrop(index,item,singleImageListData?.productCode)}>

                                                                    {loadingInImage[index] ? (
                                                                        <Box className="uploadingimageProductSize">
                                                                            <CircularProgress />
                                                                        </Box>
                                                                    ) : (
                                                                        <>
                                                                            {item.image != "" ?
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            <>
                                                                                                <img src={item.image} alt="...loading" className="imageProductSize" />

                                                                                            </>
                                                                                        }
                                                                                        {item?.uploading == true ?
                                                                                            <Typography className="uploadingImage">{t("imageupload.uploading")}</Typography>
                                                                                            : ""}

                                                                                    </>
                                                                                ) : (
                                                                                    <FileUploader
                                                                                        handleChange={(e: any) => handleFileUpload(e, singleImageListData?.productCode, index + 1,true,"")}
                                                                                        name="file"
                                                                                        types={fileTypes}
                                                                                        label={t("imageupload.upload_or_drop_file")}
                                                                                        maxSize="10"
                                                                                        disabled={loggedInUserType == CONSTANTS.USER_TYPE_ADMIN && productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? true : false}
                                                                                        onTypeError={() => fileTypeChange()}
                                                                                        onSizeError={() => fileSizeEcxeed()}
                                                                                        classes="file_uploader_in_image_upload"
                                                                                        sx={{ maxWidth: 2 }}
                                                                                    >
                                                                                        <Box className={`image_uploader_box ${loggedInUserType == CONSTANTS.USER_TYPE_ADMIN && productManagementPermission?.permissions?.execute == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? "disable_image_uploader_box" : ""}`}>
                                                                                            <Typography className="image_uploader_text">{t("imageupload.upload_or_drop_file")}</Typography>
                                                                                        </Box>
                                                                                    </FileUploader>
                                                                                )}
                                                                            <Typography variant="h6" className="productImageNo">{index + 1}</Typography>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                    );
                                                }) : ""}
                                                {/* Other Image Ends */}
                                            </Grid>

                                            <Typography align="center" variant="h6">
                                                {products?.bulkProductLogs?.length === 0 || !products?.bulkProductLogs ? t("imageupload.no_data_found") : ""}
                                            </Typography>
                                        </>
                                    )}
                                </Paper>

                            </Grid>


                        </Grid>

                    </>
                </Container>
                {/* Table data ends here */}
            </Box>
            {sizeModalOpen ?
                <ModalUtility msg={t("imageupload.file_size_exceed_to_5mb")} setModalOpen={setSizeModalOpen} modalOpen={sizeModalOpen} />
                : ""
            }
            {typeModalOpen ?
                <ModalUtility msg={t("imageupload.invalid_file_extension")} setModalOpen={setTypeModalOpen} modalOpen={typeModalOpen} />
                : ""
            }
            {imgMustModalOpen ?
                <ModalUtility msg={t("imageupload.At_least_1_image_must_be_registered")} setModalOpen={setImgMustModalOpen} modalOpen={imgMustModalOpen} />
                : ""
            }
            {timerModalOpen ?
                <ModalUtility msg={t("imageupload.reupload_msg")} setModalOpen={timerModalOpen} modalOpen={timerModalOpen} reload={true} />
                : ""
            }
        </>
    );
};

export default ImageUpload;