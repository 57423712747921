import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { commonActions } from "../redux/features/common/commonSlice";




const useRefreshBlocker = () => {
    
    const refresh = useAppSelector((state) => state.common.isReloadPage) ?? false;
    const [blockRefresh, setBlockRefresh] = useState(false);
    const [userDecision, setUserDecision] = useState <any>();
    const dispatch = useAppDispatch();

    
    const handleBeforeUnloadDecision = (event:any) => {
        event.preventDefault();
        event.returnValue = ""; // Needed for Chrome
        setUserDecision("reload");
    };

    const handleCancelDecision = () => {
        setUserDecision("cancel");
    };

    useEffect(() => {

        if (refresh) {
            window.addEventListener("beforeunload", handleBeforeUnloadDecision);

        } else {
            // dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
            window.removeEventListener("beforeunload", handleBeforeUnloadDecision);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnloadDecision);
        };

    }, [refresh]);


    return {
        blockRefresh,
        setBlockRefresh,
        userDecision,
        handleCancelDecision,
        handleBeforeUnloadDecision

    };

};


export default useRefreshBlocker;

