import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { API } from "../../../config";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "../../../utils/Cookies";
import VerifyInvitationService from "./service/VerifyInvitationService";
import CONSTANTS from "../../../constants/constants";
import { ROUTES } from "../../../constants/routes";
function VerifyInvitation() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [params,setParams] = useSearchParams();
    const [message, setMessage] = useState(t("verify_invitation.please_wait"));
    const [cookie, setCookie] = useState(new Cookies("access-token").read());
    const [showButton, setShowButton] = useState(false);
    
    useEffect(() => {
        verifyLink();
    }, []);

    const verifyLink = () => {
        const responseCode = params.get("code");
        const userType = params.get("type");
        const payload = {"userType": userType};
        const isHitDummyURL = false;
        new VerifyInvitationService(payload,isHitDummyURL).verifyInviteLink(responseCode).then((res)=>{
            if(cookie){
                const msg = t("verify_invitation.please_open_in_new_window");
                setMessage(msg);
                setShowButton(false);
            }
            else {
                navigate(`${ROUTES.REGISTER}?code=${responseCode}&type=${userType}` ,{state: { prevPath: location.pathname }});
            }
        }).catch((err)=>{
            setMessage(err?.message);
            setShowButton(true);
        });
    };

    const handleClick = () => {
        navigate(ROUTES.INITIAL_PAGE);
    };
        
    return (
        <>
            <Grid container justifyContent={"center"} sx={{py: 30}}>
                <Grid item xs={12} textAlign={"center"} height={100} >
                    <Typography>{message}</Typography>
                </Grid>
                <Grid item xs={12} textAlign={"center"} height={100} >
                    {
                        showButton ? <Button onClick={handleClick} sx={{ my: 2, px: 3, py: 1 }}>{t("verify_invitation.continue")}</Button> : null
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default VerifyInvitation;

