import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
    Autocomplete,
    Chip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import StoreDetailsService from "../services/StoreDetailsService";
import SimpleReactValidator from "simple-react-validator";
import CONSTANTS from "../../../constants/constants";
import { Route, useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GoogleMapServices from "../../../utils/GoogleMapServices";
import { userActions } from "../../../redux/features/user/userSlice";
import { commonActions } from "../../../redux/features/common/commonSlice";
import ProfileService from "../services/ProfileService";
import Skeleton from "../../common/Skeleton";
import CommonButton from "../../common/Button";
import { userDetailsInitialValue } from "../../../interfaces/userDetailInterface";

const StoreDetails = (props: any) => {
    const [toggle, setToggle] = useState<boolean>(false);
    // for fetching user store details

    useEffect(() => {
        getUserDetails();
    }, []);

    const [data,setData]=useState<any>();

    const getUserDetails = async () => {
        const isHitDummyURL = false;
        await new ProfileService({}, isHitDummyURL)
            .sellerInfo()
            .then((res: any) => {
                setIsset(true);
                dispatch(userActions.USER_DETAILS(res));
                setData(res);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };
        
    useEffect(()=>{
        setExhibitionRepUsername(data?.name);
        setCompnayName(data?.store?.[0]?.companyName);
        setCorporateName(data?.store?.[0]?.corporateName);
        setCorporateNumber(data?.store?.[0]?.corporateNumber?.toString());
        setPhoneNumber(data?.store?.[0]?.phoneNumber?.toString());
        setZipcode(data?.store?.[0]?.zipcode?.toString());
        setState(data?.store?.[0]?.state);
        setCity(data?.store?.[0]?.city);
        setStreetNumber(data?.store?.[0]?.streetNumber);
        setAddress(data?.store?.[0]?.address);
        setProductRepSurname(data?.productRepresentativeInfo?.surName);
        setProductRepName(data?.productRepresentativeInfo?.name);
        setProductRepTelephone(data?.productRepresentativeInfo?.phoneNumber?.toString());
        setPlateformFeeRate(data?.store?.[0]?.businessPartnerInfo?.mpFeeRate);
        setStorename(data?.store?.[0]?.storeName);
        setBrands(data?.brands);
        setBrandsArray(brands);
        setInquiryphonenumber(data?.store?.[0]?.inquiryPhoneNumber);
        setPartnercode(data?.store?.[0]?.businessPartnerInfo?.partnerCode);
        setpartnername(data?.store?.[0]?.businessPartnerInfo?.partnerName);
        setInquiryEmailAddress(data?.store?.[0]?.inquiryEmail);
        setQualify(data?.store?.[0]?.registrationNumber);
        setExhibitionRepEmail(data?.email);
        setInquiryPossibleTime(data?.store?.[0]?.inquiryPossibleTime);
        
    },[data]);
    
    // for fetching user store detail ends
   

    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState<any>(useAppSelector(state => state?.user?.userDetails) ?? userDetailsInitialValue.userDetails);
    const userType = useAppSelector(state => state?.permission?.permissionDetails?.userType) ?? null;
    const { handleLoader, showSnackbar } = props;
    const { t, i18n } = useTranslation();
    const [companyName, setCompnayName] = useState(userDetails?.store?.[0]?.companyName);
    const [corporateName, setCorporateName] = useState(userDetails?.store?.[0]?.corporateName);
    const [corporateNumber, setCorporateNumber] = useState(userDetails?.store?.[0]?.corporateNumber?.toString());
    const [phoneNumber, setPhoneNumber] = useState(userDetails?.store?.[0]?.phoneNumber?.toString());
    const [zipcode, setZipcode] = useState(userDetails?.store?.[0]?.zipcode?.toString());
    const [state, setState] = useState(userDetails?.store?.[0]?.state);
    const [city, setCity] = useState(userDetails?.store?.[0]?.city);
    const [streetNumber, setStreetNumber] = useState(userDetails?.store?.[0]?.streetNumber);
    const [address, setAddress] = useState(userDetails?.store?.[0]?.address);
    const [exhibitionRepUsername, setExhibitionRepUsername] = useState<any>(userDetails?.name);
    const [exhibitionRepEmail, setExhibitionRepEmail] = useState(userDetails?.email);
    const [isset,setIsset] = useState(false);
    const [exhibitionRepPassword, setExhibitionRepPassword] = useState(CONSTANTS.PASSWORD_TEXT);
    const [productRepSurname, setProductRepSurname] = useState(userDetails?.productRepresentativeInfo?.surName);
    const [productRepName, setProductRepName] = useState(userDetails?.productRepresentativeInfo?.name);
    const [productRepTelephone, setProductRepTelephone] = useState(userDetails?.productRepresentativeInfo?.phoneNumber?.toString());
    const [plateformFeeRate, setPlateformFeeRate] = useState(userDetails?.store?.[0]?.businessPartnerInfo?.mpFeeRate);
    const [storename, setStorename] = useState(userDetails?.store?.[0]?.storeName);
    const [brands, setBrands] = useState<any>(userDetails?.brands);
    const [brandsArray,setBrandsArray] = useState<any>(brands);
    const [inquiryphonenumber, setInquiryphonenumber] = useState(userDetails?.store?.[0]?.inquiryPhoneNumber);
    const [partnercode, setPartnercode] = useState(userDetails?.store?.[0]?.businessPartnerInfo?.partnerCode);
    const [partnername, setpartnername] = useState(userDetails?.store?.[0]?.businessPartnerInfo?.partnerName);
    const [inquiryEmailAddress, setInquiryEmailAddress] = useState(userDetails?.store?.[0]?.inquiryEmail);
    const [qualify, setQualify] = useState<any>(userDetails?.store?.[0]?.registrationNumber);
    const [inquiryPossibleTime, setInquiryPossibleTime] = useState<any>(userDetails?.store?.[0]?.inquiryPossibleTime);
    const [fullWidthL, setFullWidthL] = useState<number>(0);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const isDecimalNumber = (value: string) => { return /^(0|\d{1,2}(\.\d{1,2})?|100)$/.test(value);};

    const dispatch = useAppDispatch();
    // validation
    const [, forceUpdate] = useState(0);
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("registerpage.please_enter_valid_email"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                maxChar200ValidationSingleByte: {
                    message: t("registerpage.max_char_single_byte",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                SingleByte: {
                    message: t("registerpage.max_char_single_byte",{size:200}),
                    rule: (value) => isSingleByte(value),
                    
                },
                maxChar200Validation: {
                    message: t("registerpage.max_char",{size:200}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar100Validation: {
                    message: t("registerpage.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar10Validation: {
                    message: t("registerpage.max_char",{size:10}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar20Validation: {
                    message: t("registerpage.max_char",{size:20}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar80Validation: {
                    message: t("registerpage.max_char",{size:80}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                corporateNumber: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteCorporateNumber: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value) => isSingleByte(value),
                },
                phoneNumber: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: Array<number>) => {
                        return params.includes(value.toString().length);
                    },
                },
                SingleBytePhoneNumber: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),
                },
                zipcode: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteZipcode: {
                    message: t("registerpage.min_char",{size:7}),
                    rule: (value) => isSingleByte(value),
                },
                Partnercode: {
                    message: t("registerpage.min_char",{size:6}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    },
                },
                SingleByteNumber: {
                    message: t("registerpage.single_byte_numbers"),
                    rule: (value) => isSingleByte(value),
                },

                decimalNumber: {
                    message: t("registerpage.decimal_point_numbers"),
                    rule: (value) => isDecimalNumber(value),
                },
                
                telephoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: any) => {
                        return  params.includes(value.toString().length);
                    }
                },
                SingleByteTelephoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),
                },
                phoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}).toString(),
                    rule: (value: number, params: any) => {
                        return  params.includes(value.toString().length);
                    }
                },
                SingleBytePhoneValidation: {
                    message: t("registerpage.min_11_max_12_char", {min: 10, max: 11}),
                    rule: (value) => isSingleByte(value),
                },
                corporateNumberValidation: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value: number, params: number) => {
                        return value.toString().length == params;
                    }   
                },
                SingleByteCorporateNumberValidation: {
                    message: t("registerpage.min_char",{size:13}),
                    rule: (value) => isSingleByte(value),
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation
    useEffect(()=>{
        setBrands(brandsArray);
    },[brandsArray]);

    
    function onSumbit(){
        // setBrandsArray([...brandsArray,...brands]);
        StoreDetailsValidation();  
    }
    
    const StoreDetailsValidation = () => {
        if (validator.current.allValid()) {
            handleLoader(true); 
            userType != CONSTANTS.USER_TYPE_SUPER_ADMIN ? dispatch(commonActions.SAVE_LOGIN_USER_NAME(exhibitionRepUsername)):null;  
            const payload={
                "storeName": storename,
                "brands": brandsArray.length > 0 ? brandsArray : brands.length > 0 ? [brands] : [],
                "name": exhibitionRepUsername,
                "registrationNumber": qualify,
                "companyInfo":{
                    "companyName": companyName,
                    "corporateName": corporateName,
                    "corporateNumber": corporateNumber,
                    "phoneNumber": phoneNumber,
                    "zipcode": zipcode,
                    "state": state,
                    "city": city,
                    "streetNumber": streetNumber,
                    "address": address,
                    "inquiryEmailAddress": inquiryEmailAddress,
                    "inquiryPhoneNumber": inquiryphonenumber,
                    "inquiryPossibleTime":inquiryPossibleTime,
                },
                "productRepresentativeInfo":{
                    "name": productRepName,
                    "surname": productRepSurname,
                    "phoneNumber": productRepTelephone
                },
                "businessPartnerInfo": {
                    "mpFeeRate": plateformFeeRate,
                    "partnerCode": partnercode,
                    "partnerName": partnername
                }
            };
            const isHitDummyURL = false;
            new StoreDetailsService(payload, isHitDummyURL)
                .saveStoreDetails(userDetails?.store?.[0].id ?? null)
                .then((res: any) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    dispatch(userActions.USER_DETAILS(payload));
                    dispatch(commonActions.SAVE_STORE_NAME(storename));
                    navigate(ROUTES.PROFILE);
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
        let platformFee=null;
        name=="plateformFeeRate" && e.target.value ? ( platformFee = ((e.target.value ? e.target.value.trim() : 0)),
        platformFee = platformFee.replace(/^0*(?!$)(\d+)/, "$1"),
        setPlateformFeeRate(platformFee)): null;
        
    };
    const handleOnKeyUp = (event:any) => {
        const value = event.target.value;
        const decimalIndex = value.indexOf(".");
        if(!value.startsWith("00")){
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
                if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 1) {
                    const truncatedValue = value.slice(0, decimalIndex + 2);
                    event.target.value = truncatedValue;
                }
            }
            if (event.code === "Space") {
                event.preventDefault();
            }
        }else{
            event.target.value="0";
        }

    };
    const mpFeeRateHandleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^\d０-９．.]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //         e.target.value = e.target.value.replace(/[\uFF0E]/g, ".");
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
        // const decimalIndex = e.target.value.indexOf(".");
        // if (decimalIndex !== -1) {
        //     const digitsAfterDecimal = e.target.value.substring(decimalIndex + 1);
        //     if (digitsAfterDecimal.length > 2) {
        //         e.target.value = e.target.value.slice(0, decimalIndex + 3);
        //     }
        // }     
      
    };

    // function validateSingleByte(e:any) {
    //     const textEncoder = new TextEncoder();
    //     const inputBytes = textEncoder.encode(e.value);
    //     if (inputBytes.length !== e.value.length) {
    //         alert("Please enter only single byte characters.");
    //         e.value = "";
            
    //     }
    // }

    // const handleNumberInput= (e: any) => {
    
    //     // if ([69, 187, 188, 189, 190].includes(e.keyCode)) {
    //     //     e.preventDefault();   }    
    //     const prohibitedKeys = ["e", "=", ",", "-", "."];
    //     if (prohibitedKeys.includes(e.key)) {
    //         e.preventDefault();
    //     } 
    // };
      

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };

    const getStateAndCityFromZipCode = (postalCode: string) => {
        setZipcode(postalCode);
        // validator.current.hideMessageFor("zip_code");
        new GoogleMapServices().getAddressByZipCode(parseInt(postalCode!), i18n.language).then(res => {
            if(res[0]){
                const { address_components} = res[0];
                for ( let i = 0; i < address_components.length; i++){
                    if(address_components[i].types.includes("sublocality")){
                        setStreetNumber(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("locality")){
                        setCity(address_components[i].long_name);
                    }
                    if(address_components[i].types.includes("administrative_area_level_1")){
                        setState(address_components[i].long_name);
                    }
                }
            }
        }).catch(err => {
            showSnackbar(err?.message, false);
        });
    };
    
    function handleEnter(e:any) {
        if(e.keyCode==13){
            const currentValue=e.target.value.trim() ? e.target.value.trim(): 0;
            currentValue && !brandsArray.includes(currentValue) ?  setBrandsArray([...brandsArray,brands]) : setBrandsArray([...brandsArray]);
        }    
    }

    return (
        <>
            {/* Page header title */}
            <HeaderTitle title={t("store_details.store_details_title")} />
            {/* Page header title */}
           
            <Container>
                {!isset ? <Skeleton
                    varient="rectangular"
                    width={0}
                    height={60}
                    count="10"/> : 
                    <>
                        {/*Company Information start here */}
                        <Box className="ragistration_box">
                            <Typography variant="h6">
                                {t("store_details.company_information")}
                            </Typography>
                            <Box className="bg-gray">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            value={companyName ?? ""}
                                            name="company_name"
                                            label={t("store_details.company_name")}
                                            onChange={(e) => {
                                                setCompnayName(e.target.value);
                                                // validator.current.hideMessageFor("company_name");
                                            }}
                                   
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("company_name", companyName, [
                                            "required",
                                            {SingleByte: 200},
                                            { maxChar200ValidationSingleByte: 200 },
                                        ])}

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            value={corporateName ?? ""}
                                            name="corporate_name"
                                            label={t("store_details.corporate_name")}
                                            onChange={(e) => {
                                                setCorporateName(e.target.value);
                                                validator.current.hideMessageFor("corporate_name");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("corporate_name", corporateName, [
                                            "required",
                                            { maxChar200Validation: 200 },
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={corporateNumber ?? ""}
                                            name="corporate_number"
                                            label={t("store_details.corporate_number")}
                                            onChange={(e) => {
                                                setCorporateNumber(e.target.value);
                                                // validator.current.hideMessageFor("corporate_number");
                                            }}                                 
                                            onInput={(e) => handleInputType(e)} 
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message(
                                            "corporate_number",
                                            corporateNumber,
                                            [{SingleByteCorporateNumber: 13},{ corporateNumber: 13 }, ] 
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            value={phoneNumber ?? ""}
                                            name="main_phone_number"
                                            label={t("store_details.main_phone_number")}
                                            onChange={(e) => {
                                                setPhoneNumber(e.target.value);
                                                // validator.current.hideMessageFor("main_phone_number");
                                            }}                              
                                            onInput={(e) => handleInputType(e)}     
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("main_phone_number", phoneNumber, [
                                            "required",
                                            {SingleBytePhoneNumber: [10,11]},
                                            { phoneNumber: [10,11] }
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={zipcode ?? ""}
                                            name="zip_code"
                                            label={t("store_details.zip_code")}
                                            required
                                            inputProps={{ maxLength: 7 }}
                                            onChange={(e) => {
                                                getStateAndCityFromZipCode(e.target.value);
                                            }}                        
                                            onInput={(e) => handleInputType(e)}           
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("zip_code", zipcode, [
                                            "required",
                                            {SingleByteZipcode: 7},
                                            { zipcode: 7 },
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={state || ""}
                                            name="state"
                                            label={t("store_details.state")}
                                            required
                                            onChange={(e) => {
                                                setState(e.target.value);
                                                validator.current.hideMessageFor("state");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("state", state, ["required", {maxChar10Validation: 10}])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={city || ""}
                                            name="city"
                                            label={t("store_details.city")}
                                            required
                                            onChange={(e) => {
                                                setCity(e.target.value);
                                                validator.current.hideMessageFor("city");
                                            }}                                    
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("city", city, ["required", {maxChar20Validation:20}])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={streetNumber || ""}
                                            name="street_address"
                                            label={t("store_details.street_address")}
                                            required
                                            onChange={(e) => {
                                                setStreetNumber(e.target.value);
                                                validator.current.hideMessageFor("street_address");
                                            }}                                    
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("street_address", streetNumber, [
                                            "required", {maxChar100Validation: 100}
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={address ?? ""}
                                            name="complete_address"
                                            label={t("store_details.address_building_name")}
                                            // required
                                            onChange={(e) => {
                                                setAddress(e.target.value);
                                                validator.current.hideMessageFor("complete_address");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message(
                                            "complete_address",
                                            address,
                                            [ { maxChar100Validation: 100 }]
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            value={inquiryEmailAddress ?? ""}
                                            name="email"
                                            label={t("store_details.inquiry_email_address")}
                                            onChange={(e) => {
                                                setInquiryEmailAddress(e.target.value);
                                                validator.current.hideMessageFor("email");
                                            }}                                    
                                    
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("email", inquiryEmailAddress, [
                                            "required",
                                            "email",
                                            {maxChar80Validation: 80 }
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            value={inquiryphonenumber ?? ""}
                                            name="inquiry_phone_number"
                                            label={t("store_details.inquiry_phone_number")}
                                            onChange={(e) => {
                                                setInquiryphonenumber(e.target.value);
                                                // validator.current.hideMessageFor("inquiry_phone_number");
                                            }}                              
                                            onInput={(e) => handleInputType(e)}       
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("inquiry_phone_number", inquiryphonenumber, [
                                            "required",
                                            {SingleBytePhoneValidation: [10,11]},
                                            { phoneValidation: [10, 11] },
                                        ])}
                                    </Grid>
                                    <Grid item xs={0.7} sm={1} md={0.2}>
                                        <Typography variant="h4" className="store_text_margin_left">T</Typography>
                                    </Grid>
                                    <Grid item xs={11} sm={5} md={5.8}>
                                        
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={qualify ?? ""}
                                            name="qualified_billing_business_registration_number"
                                            label={t("store_details.qualified_billing_business_registration_number")}
                                            onChange={(e) => {
                                                setQualify(e.target.value);
                                                // validator.current.hideMessageFor("qualified_billing_business_registration_number");
                                            }}                                    
                                            onInput={(e) => handleInputType(e)}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message(
                                            "qualified_billing_business_registration_number",
                                            qualify,
                                            [{SingleByteCorporateNumber: 13}, { corporateNumber: 13 }]
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={inquiryPossibleTime ?? ""}
                                            name="inquiry_possible_time"
                                            label={t("store_details.inquiry_possible_time")}
                                            onChange={(e) => {
                                                setInquiryPossibleTime(e.target.value);
                                                validator.current.hideMessageFor("inquiry_possible_time");
                                            }}                                    
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message(
                                            "inquiry_possible_time",
                                            inquiryPossibleTime,
                                            [ { maxChar200Validation: 200 }]
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {/*Company Information ends here */}
                        {/* Exhibition Representative Information start here */}
                        <Box className="ragistration_box">
                            <Typography variant="h6">
                                {t("store_details.exhibition_representative")}
                            </Typography>
                            <Box className="bg-gray">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                value={exhibitionRepUsername ?? ""}
                                                name="exhibition_rep_username"
                                                label={t("store_details.user_name")}
                                                required
                                                onChange={(e) => {
                                                    setExhibitionRepUsername(e.target.value);
                                                    validator.current.hideMessageFor("exhibition_rep_username");
                                                }}
                                                onBlur={(e) => handleOnBlur(e)}
                                            />
                                            {validator.current.message("exhibition_rep_username", exhibitionRepUsername, [
                                                "required",
                                                { maxChar200Validation: 200 },
                                            ])}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                disabled
                                                type="text"
                                                value={exhibitionRepEmail ?? ""}
                                                name="exhibition_rep_emailaddress"
                                                label={t("store_details.email_address")}
                                                // required
                                                onBlur={(e) => handleOnBlur(e)}
                                            />
                                            {/* {validator.current.message("exhibition_rep_emailaddress", exhibitionRepEmail, ["required"])} */}
                                        </Grid>
                            
                                        <Grid>
                                            <CommonButton route={window.location.pathname} functionToExecute={() => navigate(ROUTES.EMAIL_UPDATE)} className="btn_custom_inline mt-20 ml-0 float-rt"  btnName={t("exhibition_user_list.update")} disableCondition={false}/>
                                            {/* <Button  className="btn_custom_inline mt-20 ml-0 float-rt" onClick={() => navigate(ROUTES.EMAIL_UPDATE)}>{t("exhibition_user_list.update")}</Button> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                disabled
                                                type="text"
                                                value={exhibitionRepPassword ?? ""}
                                                name="exhibition_rep_password"
                                                label={t("store_details.password")}
                                                // required
                                                onBlur={(e) => handleOnBlur(e)}
                                            />
                                            {/* {validator.current.message("exhibition_rep_password", exhibitionRepPassword, ["required"])} */}
                                        </Grid>
                                        <Grid>
                                            {/* <Button  className="btn_custom_inline mt-20 ml-0 float-rt" onClick={() => navigate(ROUTES.VERIFY_PASSWORD)}>{t("exhibition_user_list.update")}</Button> */}
                                            <CommonButton route={window.location.pathname} functionToExecute={() => navigate(ROUTES.VERIFY_PASSWORD)} className="btn_custom_inline mt-20 ml-0 float-rt"  btnName={t("exhibition_user_list.update")} disableCondition={false}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {/* Exhibition Representative Information start here */}
                        {/* Product Representative Information start here */}
                        <Box className="ragistration_box">
                            <Typography variant="h6">
                                {t("store_details.product_representative")}
                            </Typography>
                            <Box className="bg-gray">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={productRepSurname ?? ""}
                                            name="product_rep_surname"
                                            label={t("store_details.surname")}
                                            required
                                            onChange={(e) => {
                                                setProductRepSurname(e.target.value);
                                                validator.current.hideMessageFor("product_rep_surname");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("product_rep_surname", productRepSurname, [
                                            "required",
                                            { maxChar200Validation: 200 },
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={productRepName ?? ""}
                                            name="product_rep_name"
                                            label={t("store_details.name")}
                                            required
                                            onChange={(e) => {
                                                setProductRepName(e.target.value);
                                                validator.current.hideMessageFor("product_rep_name");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("product_rep_name", productRepName, [
                                            "required",
                                            { maxChar200Validation: 200 },
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={productRepTelephone ?? ""}
                                            name="product_rep_telephone"
                                            label={t("store_details.telephone_number")}
                                            required
                                            onChange={(e) => {
                                                setProductRepTelephone(e.target.value);
                                                // validator.current.hideMessageFor("product_rep_telephone");
                                            }}
                                            onInput={(e) => handleInputType(e)}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("product_rep_telephone", productRepTelephone, [
                                            "required",
                                            {SingleByteTelephoneValidation: [10,11]},
                                            { telephoneValidation: [10,11] },
                                        ])}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {/* Product Representative Information start here */}

                        {/* Store Name Start here */}
                        <Box className="ragistration_box">
                            <Typography variant="h6">{t("store_details.store_name")}</Typography>
                            <Box className="bg-gray">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={storename ?? ""}
                                            name="store_name"
                                            label={t("store_details.store_name")}
                                            required
                                            onChange={(e) => {
                                                setStorename(e.target.value);
                                                validator.current.hideMessageFor("store_name");
                                            }}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("store_name", storename, [
                                            "required",
                                            { maxChar100Validation: 100 },
                                        ])}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {/* Store Name Ends here */}

                        {/* Brand Name Start here */}
                        {/* {
                            userDetails.id != null ? (
                                <Box className="ragistration_box">
                                    <Typography variant="h6">{t("store_details.brand_name")}</Typography>
                                    <Box className="bg-gray">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                    multiple
                                                    options={[]}
                                                    value={brandsArray}
                                                    freeSolo
                                                    onChange={(e, value, situation, option) => {
                                                        if (situation === CONSTANTS.REMOVE_OPTION) { 
                                                            setBrandsArray(brands.filter(function(v:any) { return v != option?.option; })); 
                                            
                                                        }
                                                        if (situation === CONSTANTS.CLEAR) { 
                                                            setBrandsArray([]); 
                                                        }
                                        
                                                    }}
                                                    onInputChange={(event, newInputValue, reason) => {
                                                        if (reason === CONSTANTS.CLEAR) {
                                                            setBrands([]);
                                                            return;
                                                        }
                                       
                                                    }}
                                    
                                                    // value={brands}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id="brand-data"
                                                            type="text"
                                                            name="brand_name"
                                                            variant="outlined"
                                                            label={t("store_details.brand_name")}
                                                            onBlur={(e) => handleOnBlur(e)}
                                                            onChange={(e) => {
                                                                setBrands(e.target.value.trim());
                                                                validator.current.hideMessageFor("brand_name");
                                                
                                                            }}
                                                            onKeyDown={(e:any)=>{
                                                                handleEnter(e);
                                                            }}
                                                        />
                                                    )}
                                                    renderTags={(tagValue, getTagProps) => {
                                                        return tagValue.filter((e)=>e==e.trim()).map((option, index) => (
                                                            <Chip {...getTagProps({ index })} label={option} key={index} />
                                                        ));
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ) : null
                        } */}
                        <Box className="ragistration_box">
                            <Typography variant="h6">{t("store_details.business_partner_information")}</Typography>
                            <Box className="bg-gray">
                        
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            
                                            name="plateformFeeRate"
                                            label={t("store_details.mp_fee_rate")}
                                            required
                                            disabled={(userType==CONSTANTS.USER_TYPE_ADMIN || userType==CONSTANTS.USER_TYPE_SUPER_ADMIN) ? false : true}            
                                            onBlur={(e) => handleOnBlur(e)}
                                            value={plateformFeeRate ? plateformFeeRate : ""}
                                            onChange={(e:any)=>{
                                                setPlateformFeeRate(e.target.value);                        
                                                // setPlateformFeeRate(/^(?:[０-９\d]{2}[．.])?[０-９\d]{0,2}$/.test(e.target.value) ? e.target.value : plateformFeeRate);
                                                // validator.current.hideMessageFor("plateformFeeRate");
                                            }}
                                            // onInput={(e) => mpFeeRateHandleInputType(e)}
                                            // onKeyDown={(e) => handleOnKeyUp(e)} 
                                        />
                                        {(userType==CONSTANTS.USER_TYPE_ADMIN || userType==CONSTANTS.USER_TYPE_SUPER_ADMIN) ? validator.current.message("plateformFeeRate", plateformFeeRate, [
                                            "required",
                                            "SingleByteNumber",
                                            "decimalNumber",
                                            { maxChar200Validation: 200 },
                                            
                                        ]):null}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            disabled
                                            type="text"
                                            value={partnercode ?? ""}
                                            inputProps={{ maxLength: 6 }}
                                            name="Partner_code"
                                            label={t("store_details.partner_code")}
                                            onChange={(e) => {
                                                setPartnercode(e.target.value);
                                                // validator.current.hideMessageFor("Partner_code");
                                            }}
                                            onInput={(e) => handleInputType(e)}
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        {validator.current.message("Partner_code", partnercode, [
                                            "required",
                                            "SingleByteNumber",
                                            { Partnercode: 6 },
                                        ])}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            type="text"
                                            disabled={(userType==CONSTANTS.USER_TYPE_ADMIN || userType==CONSTANTS.USER_TYPE_SUPER_ADMIN) ? false : true} 
                                            value={partnername ?? ""}
                                            name="partner_name"
                                            label={t("store_details.partner_name")}
                                            
                                            onChange={(e) => {
                                                setpartnername(e.target.value);
                                                validator.current.hideMessageFor("partner_name");
                                            }}
                                           
                                            onBlur={(e) => handleOnBlur(e)}
                                        />
                                        
                                        {validator.current.message(
                                            "partner_name", partnername,
                                            [ "required" ,{maxChar80Validation: 80 }])}
                                            
                                    </Grid>
                                </Grid>
                       
                            </Box>
                        </Box>
                        {/* Brand Name Ends here */}

                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <CommonButton route={window.location.pathname} functionToExecute={onSumbit} className="wd_100 padding_1rem"  btnName={t("store_details.save")} disableCondition={false}/>
                                {/* <Button onClick={onSumbit} fullWidth>
                                    {t("store_details.save")}
                                </Button> */}
                            </Grid>
                        </Grid>
                        {/*Payee Information starts here */}
                    </> 
                }
            </Container>


        </>
                      
    );
};
export default StoreDetails;