import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import "../../../theme/scss/style.scss";
import { useTranslation } from "react-i18next";

const HeaderCard = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <Container className="bg-gray">
                <Grid
                    container
                    spacing={2}
                    sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}
                >
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Box className="mange-order-checkbox">
                            <Checkbox size="small" />
                            <Typography sx={{ display: "inline-block" }} variant="h6">
                                {t("manage_order.order_date")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">
                            {t("manage_order.order_details")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography variant="h6">
                            {t("manage_order.product_information")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">
                            {t("manage_order.shipping_details")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <Typography variant="h6">
                            {t("manage_order.order_status")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default HeaderCard;
