import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
class TemplateDownloadcsv {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    then: any;
    
    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    // async dowloadCsv() {
    //     let downloadCsvFile;
    //     this.isHitDummyURL && API.HIT_DUMMY_URL ?
    //         downloadCsvFile = await new AxiosUtils(`${mockAPIPath.TEMPLATE_DOWNLOAD}`, this.payload).get() :
    //         downloadCsvFile = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.TEMPLATE_DOWNLOAD}`, this.payload).get();
    //     return downloadCsvFile;
    // }
    async dowloadCsv() {

  
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.TEMPLATE_DOWNLOAD}`, this.payload, this.headers).getNew();
        let downloadCsvFile: string;
        if(response?.data?.success==true) {
            downloadCsvFile = response?.data?.data;
            return downloadCsvFile;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
        // return downloadCsvFile;

    }

    async dowloadErrorReport(id: any) {

  
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ERROR_DOWNLOAD}${id}`, this.payload, this.headers).post();
        let downloadCsvFile: string;
        if(response.data.success) {
            downloadCsvFile = response?.data;
            return downloadCsvFile;
        }
        else {
            throw new Error(response.data.message);
        }
        // return downloadCsvFile;

    }
    async templateNameChanged(url:any) {
        const response = await new AxiosUtils(url).blobRequest();  
        if(response.status==200){
            return response;

        }
        else{
            console.log("WRong Url Sent");
        }  
    }
}

export default TemplateDownloadcsv;

