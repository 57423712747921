import React, { useState, useRef, useEffect } from "react";
import { Container, Grid, TextField, Box, Button, Typography } from "@mui/material";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import OTPService from "../services/OTPService";
import { useNavigate } from "react-router";
import AuthService from "../services/AuthService";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { snackbarActions } from "../../../redux/features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../redux/hooks";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import { Link } from "react-router-dom";


const SendOTP = (props: any) => {

    const { showSnackbar,handleLoader } = props;
    const [t, i18n] = useTranslation();
 
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [alertMessage, setAlertMessage] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [check, setCheck] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("sendotp.please_enter_valid_email"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
 
    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "email" ? setEmail(value) : null;
        validator.current.hideMessageFor(name);
    };

    useEffect(() => {
        if (check == true) {
            const payload = { email, otpType: CONSTANTS.OTP_PASSWORD_TYPE };
            const isHitDummyURL = false;
            new OTPService(payload, isHitDummyURL)
                .generate()
                .then((res) => {
                    navigate(ROUTES.VERIFY_OTP, { state: { email: email} });
                }).catch((err) => showSnackbar(err?.message, false));
        }
    }, [error, check]);

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payloadEmail = { email };
            const isHitDummyURLEmail = false;
            new AuthService(payloadEmail, isHitDummyURLEmail)
                .verifyEmail()
                .then((res) => {
                    handleLoader(false);
                    setCheck(true);
                    showSnackbar(res, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, true);
                });
        } else {
            validator.current.showMessages();
        }
    };


    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            {/* Send OTP page starts here */}
            <Grid>
                <Container>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img
                                className="logo-img"
                                alt="cainz"
                                src={imagePath("logo-large.png")}
                            />
                            <Grid sx={{ mb: "1rem" }}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    name="email"
                                    label={t("sendotp.email_address")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e)=>handleOnBlur(e)}
                                    value={email}    
                                />
                                {/* validation message */}
                                {validator.current.message("email", email, ["required", "email"])}
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ my: 2 }}>

                                <Button fullWidth onClick={handleSubmit}>
                                    {t("sendotp.send_otp")}
                                </Button>
                            </Grid>
                            <Link to={ROUTES.INITIAL_PAGE}>
                                <Typography className="Order_id_link float_right">{t("sendotp.back_to_login")}</Typography>                           
                            </Link>
                        </Grid>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                    <SnackbarAlert
                        openSnackBar={openSnackBar}
                        message={alertMessage}
                        success={successAlert}
                        handleCloseSnackBar={handleCloseSnackBar}
                    />
                </Container>
            </Grid>
            {/* Send OTP page ends here */}
        </>
    );
};

export default SendOTP;
