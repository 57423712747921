import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box, TextField } from "@mui/material";
import { Container } from "@mui/system";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DeclineOrderDetail } from "../services/DeclineOrderDetail";
import { TextareaAutosize } from "@mui/base";

const Decline = (props: any) => {
    const [t, i18n] = useTranslation();
    const { handleLoader, showSnackbar } = props;
    const [value, setValue] = React.useState("OrderQuantity");
    const [customReason, setCustomReason] = React.useState("");

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        handleLoader(true);
        const reason = value ? value : customReason;
        const payload = { reason };
        const isHitDummyURL = true;
        new DeclineOrderDetail(payload, isHitDummyURL)
            .declineOrder()
            .then((res: any) => {
                handleLoader(false);
            })
            .catch((err: any) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    return (
        <>
            <div className="main">
                <HeaderTitle
                    title={t("manage_order.manage_orders")}
                />
                <Container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h4" sx={{ mt: 2 }}>
                            {t("manage_order.why_are_cancelling_order")}
                        </Typography>

                        <FormControl>
                            <FormLabel sx={{ my: 4 }} id="demo-radio-buttons-group-label">
                                {t("manage_order.select_reason")}
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="OrderQuantity"
                                    name="OrderQuantity"
                                    control={<Radio />}
                                    label={t("manage_order.order_quantity_exceeds_the_limit")}
                                />
                                <FormControlLabel
                                    value="ProductDiscontinued"
                                    name="ProductDiscontinued"
                                    control={<Radio />}
                                    label={t("manage_order.product_discontinued")}
                                />
                                <FormControlLabel
                                    value="ProductIsnot"
                                    name="ProductIsnot"
                                    control={<Radio />}
                                    label={t("manage_order.product_isn't_available_in_store")}
                                />
                                <FormControlLabel
                                    value="ReasonFour"
                                    name="ReasonFour"
                                    control={<Radio />}
                                    label={t("manage_order.reason_4")}
                                />
                                <FormControlLabel
                                    value="ReasonFive"
                                    name="ReasonFive"
                                    control={<Radio />}
                                    label={t("manage_order.reason_5")}
                                />
                                <FormControlLabel
                                    value=""
                                    name="Custom"
                                    control={<Radio />}
                                    label={t("manage_order.custom_reason")}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {!value ? (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextareaAutosize
                                className="textarea"
                                onChange={(e) => setCustomReason(e.target.value)}
                                name="reasonMessage"
                            />
                        </Grid>
                    ) : null}
                    <Grid item lg={12} sx={{ mt: 4 }}>
                        <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit}>
                            {t("manage_order.submit")}
                        </Button>
                        <Button variant="outlined" color="primary">
                            {t("manage_order.cancel")}
                        </Button>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default Decline;
