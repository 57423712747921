import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Container, Grid, TextField, Typography, Popover} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Pagination from "../common/Pagination";
import SimpleReactValidator from "simple-react-validator";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import HeaderTitle from "../common/HeaderTitle";
import SalesHistoryServices from "./services/SalesHistory";
import MUIDataTable from "mui-datatables";
import Skeleton from "../common/Skeleton";
import Utility from "../../utils/Utility";
import jaLocale from "date-fns/locale/ja";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import DownloadButton from "../common/DownloadBtn";
import { pdf } from "@react-pdf/renderer";
import { StatementTemplate } from "../common/PDFTemplates/StatementTemplate";
import { salesHistoryConfig } from "./config/salesHistoryConfig";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const SalesHistory = (props:any) => {
    const { showSnackbar, handleLoader } = props;
    const utility = new Utility();
    const firstPage = 1;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(firstPage);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null>(null);
    const [valueTo, setValueTo] = React.useState<Date | null>(null);
    const [limit, setLimit] = useState(!perPageLimit  ?  LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit);
    const [historyData, setHistoryData] = useState <any>([]);
    const [resetData, setResetData] = useState(false);
    const [dummyValue, setDummyValue] = useState <Date | null>(null);
    const [dateValue, setDateValue] = useState <string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLButtonElement | null>(null);


    const apiData: any[][] = [];

    const [, forceUpdate] = useState(0);

    useEffect(() => {
        forceUpdate(1);
    });


    const handleDateFrom = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleDateTo = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };
    const handleInsPopoverOpen = (event: any) => {
        setAnchorElPopover(event.currentTarget);
    };

    /**
 *
 * @description - Function to close instruction popover in product list
 */  
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        if(resetData){
            saleshHistoryData(currentPage,limit);
        }
    }, [resetData]);

    useEffect(()=>{
        saleshHistoryData(currentPage,limit);
    },[]);


    const saleshHistoryData = (currentPage: number, limit: number) => {
        const payload = {};
        const isHitDummyURL = false;
        setResetData(false);
        setSkeleton(true);
        new SalesHistoryServices(payload, isHitDummyURL)
            .salesHistoryList(currentPage, limit, fromParam ?? "", toParam ?? "")
            .then((res:any) => {
                setHistoryData(res?.salesHistoryLogs);
                setLimit(limit);
                setCurrentPage(res?.pagination?.currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
              
                setSkeleton(false);
            })
            .catch((err) => {
                setSkeleton(false);
                showSnackbar(err?.message, false);
            });
    };
 



    const downloadSalsesHistoryData = () => {
        setLoading(true);
        showSnackbar(t("bulk_product_index.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        new SalesHistoryServices(payload, isHitDummyURL)
            .downloadSalesHistoryList(fromParam ?? "", toParam ?? "")
            .then((res: any) => {
                setSkeleton(false);
                handleOnExport(res?.salesHistoryLogs);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setLoading(false);
                setSkeleton(false);
            });
    };

    const downloadPDF = (settlementPeriod: string) => {
        handleLoader(true);
        const payload = {};
        const isHitDummyUrl = false;
        new SalesHistoryServices(payload, isHitDummyUrl)
            .salesSatementService(settlementPeriod)
            .then(async (res) => {
                const PDFData = res;
                const settlementPeriod = utility.getCustomDateYMDJP(PDFData?.settlementPeriod);
                const updatedSettlementPeriod = settlementPeriod.replaceAll(" ", "");
                const fileName = salesHistoryConfig.PDF_DEFAULT_TEXT.concat("_", updatedSettlementPeriod, ".pdf");
                const value = await utility.printPdf(<StatementTemplate PDFData={PDFData} fileName={fileName} />, fileName, false);
                handleLoader(value);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };

    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            downloadData.push({
                "決済期間":item?.settlementPeriod,
                "受注金額":item?.orderAmount,
                "返金額":item?.refundAmount,
                "ポイント付与額":item?.grantedPointAmount,
                "Cainz手数料額":item?.cainzFee,
                "入金額":item?.transferAmount,
            });
        });
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(downloadData);
        // XLSX.utils.book_append_sheet(wb, ws, "売上入金履歴");
        // XLSX.writeFile(wb, "売上入金履歴.xlsx");
        const fileName="売上入金履歴.xlsx";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.SALES_HISTORY);
        setLoading(false);
    };

    const columns = [
        {
            name: t("sales_history.payment_period"),
            options: {
                filter: true,
                sort: false,
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
       
        {
            name: t("sales_history.order_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
        {
            name: t("sales_history.refund_amount"),
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
        {
            name: t("sales_history.amount_of_points_granted"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
        {
            name: t("sales_history.cainz_fee_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
        {
            name: t("sales_history.deposit_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"}),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
        {
            name: t("sales_history.sales_statement"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" }),
                setCellProps: () => ({
                    style: {
                        verticalAlign: "middle",
                    },
                })
            },
        },
    ];

    const tableNegativeValue = (value: any) => {
        return (
            <Box className={`${value < 0 ? "sales_history_negative_quantity_red " : "sales_history_negative_quantity_black"}`}>{utility.numberWithCommas(value)}</Box>
        );
    };

    const PDFDownloadButton = (settlementPeriod: string, value: number) => {
        const updatedSettlementPeriod = settlementPeriod.replace("~", "-");
        return (
            <DownloadButton route={window.location.pathname} functionToExecute={() => downloadPDF(updatedSettlementPeriod)} className="table_btn" btnName={t("sales_history.pdf")} disableCondition={value <= 0 ? true : false} sx={{ float: "right" }}/>
        );
    };

    const apiTableData = () => {
        {
            historyData?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    item?.settlementPeriod ,
                    tableNegativeValue(item?.orderAmount),
                    tableNegativeValue((item?.refundAmount)),
                    tableNegativeValue(item?.grantedPointAmount),
                    tableNegativeValue(item?.cainzFee),
                    tableNegativeValue(item?.transferAmount),
                    PDFDownloadButton(item?.settlementPeriod, item?.transferAmount)
                );
            });
        }
    };
    apiTableData();

    const resetAllFilter = () =>{
        setValueTo(null);
        setValueFrom(null);
        setResetData(true);
        setFromParam("");
        setToParam("");
    };

    const handleSubmit = () =>{

        if (validator.current.allValid()) {
            saleshHistoryData(firstPage, limit);     
        }
        else {
            validator.current.showMessages();
        }
    };

    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    return (
        <>
            <Box className="manage_inventory">
                <Popover
                    open={Boolean(anchorElPopover)}
                    anchorEl={anchorElPopover}
                    onClose={handleInsPopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    className="instruction_popover">
                    <Box className="instruction_popover_box">
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_1")}</Typography>
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_2")}</Typography>
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_3")}</Typography>
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_4")}</Typography>
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_5")}</Typography>
                        <Typography sx={{pb:2}}>{t("sales_history.instruction_6")}</Typography>
                    </Box>
                </Popover>
                <Container>
                    <Grid container spacing={2}>  
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <HeaderTitle title={t("sales_history.header_title")} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className="product_list_inst_icon">
                            <Box component="span" className="popover_info_box">
                                <Typography className="about_uploading_image">{t("sales_history.instruction_title")}
                                    <InfoRoundedIcon                                         
                                        onClick={handleInsPopoverOpen} 
                                        color="primary" 
                                        className="image_upload_instruction_icon"/>
                                </Typography>
                            </Box> 
                        </Grid>  

                        {/* <Grid item lg={2} sx={{mt:3}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} className="date-picker-main" >
                            <DatePicker
                                className="date-picker-default sr_history_filter"
                                label={t("sales_history.from")}
                                value={dummyValue}
                                inputFormat="yyyy/MM/dd"
                                onChange={HandleDummy}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params} 
                                        inputProps={{
                                            ...params.inputProps,
                                            readOnly: true,
                                            style: { pointerEvents: "none" },
                                        }}  

                                    />)}
                                autoFocus={true}
                               
                                // maxDate={valueTo} 
                                // shouldDisableDate={shouldDisableDateFrom} 
                                showToolbar={false}
                                componentsProps={{
                                    actionBar: {
                                        // The actions will be the same between desktop and mobile
                                        actions: []
                                    }
                                }}
                            />  
                            {validatorCustomDate.current.message("date-picker-default sr_history_filter", dummyValue, [
                                "required",
                            ])}                                                            
                        </LocalizationProvider>

                    </Grid>
                    <Grid item lg={2} sx={{mt:3}}>
                        <Button 
                            variant="contained"
                            sx={{textTransform: "capitalize", mt:1}}
                            onClick={handleDummyDataSubmit}>
                               

                            {t("pr.payment_generate")}
                        </Button>
                    </Grid> */}
                    </Grid>
                </Container>
                
                <Container>
                    <Grid container spacing={2}>                   
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className="paymentReport-datepicker">
                            <Grid container spacing={2}> 
                                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} className="date-picker-main" >
                                        <DatePicker
                                            className="date-picker-default sr_history_filter"
                                            label={t("sales_history.from")}
                                            value={valueFrom}
                                            inputFormat="yyyy/MM/dd"
                                            onChange={handleDateFrom}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params} 
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                        style: { pointerEvents: "none" },
                                                    }}  

                                                />)}
                                            autoFocus={true}
                                            disableFuture
                                            // maxDate={valueTo} 
                                            shouldDisableDate={shouldDisableDateFrom} 
                                            showToolbar={false}
                                            componentsProps={{
                                                actionBar: {
                                                    // The actions will be the same between desktop and mobile
                                                    actions: []
                                                }
                                            }}
                                        />  
                                        {validator.current.message("valuefrom", valueFrom, [
                                            "required",
                                        ])}                                                            
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} >
                                        <DatePicker
                                            className="date-picker-default sr_history_filter"
                                            label={t("sales_history.to")}
                                            value={valueTo}
                                            inputFormat="yyyy/MM/dd"
                                            onChange={handleDateTo}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params} 
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                        style: { pointerEvents: "none" },
                                                    }}  

                                                />)}                                         
                                            autoFocus={true}
                                            disableFuture
                                            shouldDisableDate={shouldDisableDateTo}
                                            // minDate={valueFrom}
                                            showToolbar={false}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: []
                                                }
                                            }}
                                        />  
                                        {validator.current.message("valueto", valueTo, [
                                            "required",
                                        ])}

                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <Button 
                                        variant="contained"
                                        sx={{textTransform: "capitalize", mt:1}}
                                        onClick={handleSubmit}
                               
                                    >
                                        {t("pr.submit")}
                                    </Button>
                                    <Button 
                                        variant="contained"                           
                                        sx={{textTransform: "capitalize", ml:2,  mt:1}}
                                        onClick={resetAllFilter}> {t("pr.reset")}
                                    </Button>
                                </Grid>
                            </Grid>              
                        </Grid>
                        <Grid item xs={6} sm={5} md={2} lg={2} xl={2}>
                            <DownloadButton loading={loading}  route={window.location.pathname} functionToExecute={downloadSalsesHistoryData} className="download_excel" btnName= {t("sales_history.download")} disableCondition={loading || historyData?.length==0 || !historyData}/>
                            {/* <Button variant="contained" disabled={loading || historyData?.length==0 || !historyData} onClick={downloadSalsesHistoryData} className="download_excel">
                                {t("sales_history.download")} {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Button> */}
                        </Grid>
                        
                    </Grid>
                </Container>
            
                <>
                    <div className="main">
                    
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={60}
                                count="10"
                                my={0}
                            />
                        ) : (
                      
                            <Container className="history_table  sales_history_first_table_cell_small_width">
                                <MUIDataTable
                                    title={""}
                                    columns={columns}
                                    data={apiData}
                                    options={{
                                        pagination: false,
                                        print: false,
                                        download: false,
                                        filter: false,
                                        viewColumns:false,
                                        search: false,
                                        selectableRowsHideCheckboxes: true,
                                        textLabels: {
                                            body: {
                                                noMatch: t("table_text.no_match").toString(),
                                            },
                                            viewColumns: {
                                                title: t("table_text.show_columns").toString(),
                                                titleAria: "Show/Hide Table Columns",
                                            },
                                            toolbar: {
                                                search: t("table_text.search").toString(),
                                                viewColumns: t("table_text.view_columns").toString(),
                                            },
                                        },
                                        searchPlaceholder: t("table_text.search_placeholder").toString(),
                                        responsive: "standard",
                               
                                    }}
                                />
                            </Container>
                        )}
                    </div>
                </>
            
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={saleshHistoryData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
                
            </Box>
          
        </>
    );
};

export default SalesHistory;
