import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Button,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import CONSTANTS, { LIST_LIMIT } from "../../constants/constants";
import HeaderTitle from "../common/HeaderTitle";
import ExhibitionUserListService from "./service/ExhibitionUserListService";
import UserPagination from "../../models/User/usersPagination";
import UserModel from "../../models/User/userModel";
import Pagination from "../common/Pagination";
import Skeleton from "../common/Skeleton";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ROUTES } from "../../constants/routes";
import MUIDataTable from "mui-datatables";
import { EXHIBITION_USER_LIST_CONSTANTS } from "./constants";
import { permissionCategory } from "../../constants/permissionCategory";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import { snackbarActions } from "../../redux/features/snackbar/snackbarSlice";
import CommonButton from "../common/Button";
import { permissionInitialValue } from "../../interfaces/permissionInterface";

const ExhibitionUsers = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    const [t, i18n] = useTranslation();
    const dispatch = useAppDispatch();

    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const pathName = window.location.pathname;
    const [search, setSearch] = useState<string>("");
    const [deleteUserId, setDeleteUserId] = useState(0);
    const [isReload, setIsReload] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    localStorage.setItem(pathName, currentPage.toString());
    const firstPage = Number(localStorage.getItem(pathName));
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [copyUrl, setCopyUrl] = useState("");
    const [url, setUrl] = useState("");
    const [status, setStatus] = useState<any>();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);

    const [
        exhibitionUserListWithPagination,
        setExhibitionUserListWithPagination,
    ] = useState<UserPagination>();
    const apiData: any[][] = [];

    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.EXHIBITION_USER_LIST_LIMIT : perPageLimit );
    const [isInvitationSent, setIsInvitationSent] = useState(false);

    const loggedInUserType = useAppSelector((state) => state.permission.permissionDetails.userType) ?? null;

    const permissions = (useAppSelector(state => state.permission.permissionDetails.modules) ?? permissionInitialValue.permissionDetails.modules)?.find(item => item.key == permissionCategory.LISTING_USER);

    const columns = [
        {
            name: t("exhibition_user_list.sno"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("exhibition_user_list.user_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("exhibition_user_list.email_address"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("exhibition_user_list.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("exhibition_user_list.action"),
            options: {
                filter: true,
                sort: false,
            },
        },
    ];

    const onTabFocus = () => {
        setIsInvitationSent(true);
    };

    const onTabBlur = () => {
        setIsInvitationSent(false);
    };

    useEffect(() => {
        window.addEventListener("focus", onTabFocus);
        window.addEventListener("blur", onTabBlur);
        onTabFocus();
        return () => {
            window.removeEventListener("focus", onTabFocus);
            window.removeEventListener("blur", onTabBlur);
        };
    }, []);

    // useEffect(() => {
    //     if (url != "") {
    //         const copyUrl = url;
    //         setCopyUrl(copyUrl);
    //         setInviteModalOpen(!modalOpen);
    //     }
    // }, [url]);

    useEffect(() => {  
        dispatch(snackbarActions.HIDE_SNACKBAR(false));
    },[]);

    const navigateInvitePage = () => {
        localStorage.setItem("userType", CONSTANTS.USER_TYPE_EXHIBITOR.toString());
        open(ROUTES.INVITE_PAGE, "_blank");
    };

    const navigate = useNavigate();

    const navigateDetailPage = (id: number,userType:any) => {
        userType == CONSTANTS.USER_TYPE_SELLER ? navigate(`${ROUTES.PROFILE}`) : navigate(`${ROUTES.EXHIBITION_USER_DETAIL}/${id}`);
    };

    useEffect(() => {
        if (isInvitationSent) {
            exhibitionUserList(firstPage, limit);
        }
    }, [isInvitationSent]);

    const onsearchApicall = () =>{
        if(isSearchChange){
            exhibitionUserList(CONSTANTS.FIRST_PAGE, limit);
        }
    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    const exhibitionUserList = (currentPage: number, limit: any) => {
        setIsSearchChange(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ExhibitionUserListService(payload, isHitDummyURL)
            .exhibitionUserList(currentPage, search.trim(), limit)
            .then((res) => {
                setCurrentPage(currentPage);
                setLimit(limit);
                setExhibitionUserListWithPagination(res);
                setTotalCount(res.pagination.totalCount);
                setTotalPages(res.pagination.totalPages);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
        apiTableData();
    };

    const deleteExhibitionUser = (deleteId: number, status: number) => {
        handleLoader(true);
        const payload = {};
        const isHitDummyURL = false;
        if (status == 1) {
            new ExhibitionUserListService(payload, isHitDummyURL)
                .deleteExhibitorUser(deleteId)
                .then((res) => {
                    handleLoader(false);
                    setIsReload(!isReload);
                    exhibitionUserList(currentPage, limit);
                    showSnackbar(res, true);
                    closeModal();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                    closeModal();

                });
        } else {
            new ExhibitionUserListService(payload, isHitDummyURL)
                .deleteInvitedExhibitor(deleteId)
                .then((res) => {
                    exhibitionUserList(currentPage, limit);
                    handleLoader(false);
                    setIsReload(!isReload);
                    showSnackbar(res, true);
                    closeModal();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                    closeModal();
                });
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        // setInviteModalOpen(false);
        setUrl("");
    };

    const deleteModalOpen = (deleteId: number, status: number) => {
        setModalOpen(true);
        setStatus(status);
        setDeleteUserId(deleteId);
    };

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            exhibitionUserList(CONSTANTS.FIRST_PAGE, limit);
        }
    };

    const handleReInvite = (email: string) => {
        handleLoader(true);
        const payload = { email: email, userType: CONSTANTS.USER_TYPE_EXHIBITOR };
        const isHitDummyURL = false;
        new ExhibitionUserListService(payload, isHitDummyURL)
            .inviteExhibitionUser()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res.message, true);
                // setUrl(res.data.url);
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    const actionButton = (
        email: string,
        userId: number,
        invitationId: number,
        status: number,
        userType: number
    ) => {
        const deleteId = invitationId;
        return (
            <>
                <Box className="table_btn">
                    <CommonButton route={window.location.pathname} functionToExecute={() => handleReInvite(email)} className="table_btn" btnName={t("exhibition_user_list.reinvite")} disableCondition={userType == CONSTANTS.USER_TYPE_SELLER ? true : permissions?.routes?.add && status != EXHIBITION_USER_LIST_CONSTANTS.STATUS_ACCEPTED ? false : true}/>

                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        disabled={userType == CONSTANTS.USER_TYPE_SELLER ? true : permissions?.routes?.add && status != EXHIBITION_USER_LIST_CONSTANTS.STATUS_ACCEPTED ? false : true}
                        onClick={() => handleReInvite(email)}
                    >
                        {t("exhibition_user_list.reinvite")}
                    </Button> */}

                    <Button 
                        className="table_btn"
                        variant="contained"
                        onClick={() => navigateDetailPage(userId,userType)}
                        // disabled={userType == CONSTANTS.USER_TYPE_SELLER ? true : status == EXHIBITION_USER_LIST_CONSTANTS.STATUS_ACCEPTED ? false : true}
                        disabled={status == EXHIBITION_USER_LIST_CONSTANTS.STATUS_ACCEPTED ? false : true}
                    >
                        {t("exhibition_user_list.show_details")}
                    </Button>
                    <CommonButton route={window.location.pathname} functionToExecute={() => deleteModalOpen(deleteId, status)} className="table_btn" btnName={t("exhibition_user_list.delete")} disableCondition={userType == CONSTANTS.USER_TYPE_SELLER ? true : permissions?.routes?.delete ? false : true}/>

                    {/* <Button  
                        className="table_btn"
                        variant="contained"
                        disabled={userType == CONSTANTS.USER_TYPE_SELLER ? true : permissions?.routes?.delete ? false : true }
                        onClick={() => deleteModalOpen(deleteId, status)}
                    >
                        {t("exhibition_user_list.delete")}
                    </Button> */}
                </Box>
            </>
        );
    };

    const apiTableData = () => {
        {
            exhibitionUserListWithPagination?.users?.map(
                (item: UserModel, index: number) => {
                    const status =
            item.status == EXHIBITION_USER_LIST_CONSTANTS.STATUS_PENDING
                ? t("exhibition_user_list.inviting")
                : item.status == EXHIBITION_USER_LIST_CONSTANTS.STATUS_EXPIRED
                    ? t("exhibition_user_list.disabled")
                    : t("exhibition_user_list.accepted");
                    apiData[index] = [];
                    apiData[index].push(
                        (currentPage - 1) * limit + index + 1,
                        item.name,
                        item.email,
                        status,
                        actionButton(
                            item.email,
                            item.userId,
                            item.invitationId,
                            item.status,
                            item.userType
                        )
                    );
                }
            );
        }
    };
    apiTableData();

    return (
        <>
            <Container className="">
                <Grid container spacing={0} sx={{ pt: 2 }}>
                   
                    {loggedInUserType != CONSTANTS.USER_TYPE_EXHIBITOR ? (<Grid item lg={6} xs={12} sm={8} md={8}> <HeaderTitle title={t("exhibition_user_list.page_title")} /> </Grid>) : <Grid item lg={12} xs={12} sm={8} md={8}><HeaderTitle title={t("exhibition_user_list.page_title")} /></Grid>}

                    {/* blank space */}
                    {permissions?.routes?.add ? (
                        <Grid item lg={6} xs={12} sm={4} md={4} pt={4}>
                            <CommonButton route={window.location.pathname} functionToExecute={navigateInvitePage} className="float_right store_invite_btn" btnName={t("exhibition_user_list.invite_exhibitor")} disableCondition={false} startIconRequired={<AddCircleIcon />}/>                     
                            {/* <Button
                                className="float_right"
                                sx={{ py: 2 }}
                                startIcon={<AddCircleIcon />}
                                onClick={navigateInvitePage}
                            >
                                {t("exhibition_user_list.invite_exhibitor")}
                            </Button> */}
                        </Grid>
                    ) : null}
                    {/* <Grid item xs={12} sm={4} md={4} lg={4} sx={{ px: 2 }}>
                        <TextField
                            id="input-with-icon-textfield"
                            label={t("exhibition_user_list.search_place_holder")}
                            onChange={handleSearchChange}
                            onKeyUp={handleKeyPress}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => exhibitionUserList(CONSTANTS.FIRST_PAGE, limit)}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            fullWidth
                        />
                    </Grid> */}
                </Grid>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <>
                        <div className="exhibition_table">
                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t(
                                        "table_text.search_placeholder"
                                    ).toString(),
                                    responsive: "standard",
                                    onSearchChange: (searchText: any) => {
                                        setSearch(searchText ? searchText : "");
                                    },
                                }}
                            />
                          
                        </div>
                    </>

                )}
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={exhibitionUserList}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />

                <Modal
                    open={modalOpen}
                    onClose={closeModal}
                    aria-labelledby="delete-modal-title"
                    aria-describedby="delete-modal-description"
                >
                    <Box className="center-model" sx={{ p: 4 }}>
                        <Typography id="delete-modal-description" sx={{ m: 2 }}>
                            {t("exhibition_user_list.confirm_message")}
                        </Typography>
                        <Button
                            sx={{ m: 2, p: 1 }}
                            onClick={() => deleteExhibitionUser(deleteUserId, status)}
                        >
                            {t("exhibition_user_list.yes")}
                        </Button>
                        <Button sx={{ m: 2, p: 1 }} onClick={closeModal}>
                            {t("exhibition_user_list.no")}
                        </Button>
                    </Box>
                </Modal>
                {/* <Modal
                    open={inviteModalOpen}
                    onClose={closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="center-model">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        ></Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {copyUrl}
                        </Typography>
                        <Button
                            sx={{ mt: 2 }}
                            onClick={() => navigator.clipboard.writeText(copyUrl)}
                        >
                            {t("invitation_page.Please_copy_this_link_to_register")}
                        </Button>
                    </Box>
                </Modal> */}
            </Container>
            {/* Table data ends here */}
        </>
    );
};

export default ExhibitionUsers;
