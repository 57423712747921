import React ,{useEffect, useState} from "react";
import TablePagination from "@mui/material/TablePagination";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaginationLimitServices from "./services/PaginationLimit";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import { snackbarUtils } from "../../../utils/snackbarUtils";

const Pagination = (props:any) => {

    const [t, i18n] = useTranslation();

    const {currentPage, totalCount, pageChange, rowsPerPage, rowsPerPageChange} = props;
    const dispatch = useAppDispatch();
    const [isPageLimitChange,setIsPageLimitChange]=useState<boolean>(false);

    const loggedInUserType = useAppSelector(
        (state) => state.permission.permissionDetails.userType
    );

    
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {  
        pageChange(newPage + 1, rowsPerPage);
        // setPage(newPage); 
        // setToggle(!toggle); 
    };

    const showSnackbar = (message: string, success: boolean, autoClose= true) => {
        snackbarUtils(dispatch,message, success, autoClose);
    };
    
    const paginationLimit = () => {
        const payload = {"perPageLimit":rowsPerPage};
        const isHitDummyURL = false;
        new PaginationLimitServices(payload, isHitDummyURL)
            .PaginationLimit()
            .then((res:any) => {
                dispatch(commonActions.SAVE_PER_PAGE_LIMIT(rowsPerPage));
                setIsPageLimitChange(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);      
            });
    };

    useEffect(()=>{
        if(location.pathname !== "/inventory-manage" && isPageLimitChange ){
            paginationLimit();
        }
    },[rowsPerPage]);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setIsPageLimitChange(true);
        rowsPerPageChange(parseInt(event.target.value ));
        pageChange(1, parseInt(event.target.value ));
       
    };

    return (
        <>
            <Container>
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage= {t("table_text.pagination_rows_per_page")}
                />
            </Container>
        </>
    );
};

export default Pagination;
