import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box, TextField } from "@mui/material";
import { Container } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import "../../../theme/scss/style.scss";
import { useTranslation } from "react-i18next";
import CurrencyYenOutlinedIcon from "@mui/icons-material/CurrencyYenOutlined";
import UnshippedOrderDetail from "../services/UnshippedOrderDetail";
import HeaderCard from "../common/HeaderCard";
import Skeleton from "../../common/Skeleton";
import { LogisticProviderDetails } from "../services/LogisticProviderDetails";

const Unshipped = (props: any) => {
    const [t, i18n] = useTranslation();
    const [showResults, setShowResults] = React.useState(false);
    const { handleLoader, showSnackbar } = props;
    const showResult = () => setShowResults(!showResults);
    useEffect(() => {
        unshippedService();
    }, []);

    const [unshippedDetail, setUnshippedDetail] = useState<any>([]);
    const unshippedService = () => {
        const payload = {};
        const isHitDummyURL = true;
        new UnshippedOrderDetail(payload, isHitDummyURL)
            .unshippedOrder()
            .then((res: any) => setUnshippedDetail(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };

    const [logisticservice, setLogisticservice] = useState("");
    const [trackingcode, setTrackingcode] = useState("");
    const [trackinglink, setTrackinglink] = useState("");

    const handleChange = (event: any) => {
        setLogisticservice(event.target.value);
        setTrackingcode(event.target.value);
        setTrackinglink(event.target.value);
    };

    const handleSubmit = () => {
        handleLoader(true);
        const payload = { logisticservice, trackingcode, trackinglink };
        const isHitDummyURL = true;
        new LogisticProviderDetails(payload, isHitDummyURL)
            .logisticData()
            .then((res: any) => {
                handleLoader(false);
            })
            .catch((err: any) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    return (
        <>
            <Container>
                <Box className="flex-end" sx={{ my: 4 }}>
                    <Button
                        className="manage_btn"
                        sx={{ mb: 2 }}
                        variant="contained"
                        disabled
                    >
                        {t("manage_order.ship_now")}
                    </Button>
                </Box>
            </Container>
            {unshippedDetail.length == 0 ? (
                <Skeleton count={10} height={100} variant="rectangular" my={0} />
            ) : (
                unshippedDetail?.map((item: any) => {
                    return (
                        <>
                            <HeaderCard />
                            <Container className="light-gray">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={2} md={2} lg={2}>
                                        <Box className="order_date">
                                            <Typography>{item.orderDate}</Typography>
                                            <Typography>{item.orderTime}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} lg={2}>
                                        <Box>
                                            <Typography>{t("manage_order.order_id")} :</Typography>
                                            <Typography color="primary">{item.orderId}</Typography>
                                        </Box>

                                        <Box sx={{ mt: 1 }}>
                                            <Typography>{t("manage_order.buyer_name")} :</Typography>
                                            <Typography> {item.buyerName}</Typography>
                                        </Box>

                                        <Box sx={{ mt: 1 }}>
                                            <Typography>
                                                {t("manage_order.sales_channel")} :
                                            </Typography>
                                            <Typography>{item.salesChannel}</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                                <Box>
                                                    <img
                                                        src={item.productInformation.productImage}
                                                        className="upload-img"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={9}>
                                                <Typography>
                                                    {item.productInformation.detail}
                                                </Typography>
                                                <Box sx={{ mt: 1 }}>
                                                    <Typography>
                                                        {t("manage_order.jan")} :
                                                        <Typography sx={{ ml: 1 }} component="span">
                                                            {item.productInformation.jan}
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ mt: 1 }}>
                                                    <Typography>
                                                        {t("manage_order.quantity")} :
                                                        <Typography sx={{ ml: 1 }} component="span">
                                                            {item.productInformation.quantity}
                                                        </Typography>
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ mt: 1 }}>
                                                    <Typography>
                                                        {t("manage_order.sub_total")} :
                                                        <Typography sx={{ ml: 1 }} component="span">
                                                            <CurrencyYenOutlinedIcon
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    top: "2px",
                                                                    position: "relative",
                                                                }}
                                                            />
                                                            {item.productInformation.subtotal}
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} lg={2}>
                                        <Box>
                                            <Typography>
                                                {t("manage_order.shipping_date_range")} :
                                            </Typography>

                                            <Typography>
                                                {item.shippingDetail.shippingdate}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ mt: 1 }}>
                                            <Typography>
                                                {t("manage_order.elapsed_time")} :
                                            </Typography>
                                            <Typography color="primary">
                                                {item.shippingDetail.elapsedTime}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ mt: 1 }}>
                                            <Typography>
                                                {t("manage_order.delivery_date")} :
                                            </Typography>
                                            <Typography>
                                                {item.shippingDetail.deleveryDate}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={4} sm={2} md={2} lg={2}>
                                        <Grid item lg={12}>
                                            <Button
                                                className="manage_btn"
                                                sx={{ mb: 2 }}
                                                variant="contained"
                                                onClick={showResult}
                                            >
                                                {t("manage_order.ship_now")}
                                            </Button>
                                            {showResults ? (
                                                <Button
                                                    className="manage_btn"
                                                    sx={{ mb: 2 }}
                                                    variant="outlined"
                                                >
                                                    {t("manage_order.print_shipping_tag")}
                                                </Button>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    {showResults ? (
                                        <>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label={t("manage_order.logistic_service_provider")}
                                                    variant="outlined"
                                                    name="logisticservice"
                                                    value={logisticservice}
                                                    onChange={(e) => setLogisticservice(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label={t("manage_order.tracking_code")}
                                                    variant="outlined"
                                                    name="trackingcode"
                                                    value={trackingcode}
                                                    onChange={(e) => setTrackingcode(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label={t("manage_order.tracking_link")}
                                                    variant="outlined"
                                                    value={trackinglink}
                                                    name="trackinglink"
                                                    onChange={(e) => setTrackinglink(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                                <Box className="flex_start">
                                                    <Button
                                                        className="manage_btn"
                                                        sx={{ mr: 2 }}
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                    >
                                                        {t("manage_order.submit")}
                                                    </Button>
                                                    <Button className="manage_btn" variant="outlined">
                                                        {t("manage_order.cancel")}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                            </Container>
                        </>
                    );
                })
            )}
        </>
    );
};

export default Unshipped;
