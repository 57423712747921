
import React,{useEffect, useState} from "react";
import { Box, Button, Container, Grid, Typography  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { imagePath } from "../../utils/assetUtils";
import UnderMaintenanceService from "./services/UnderMaintenanceService";
import { ROUTES } from "../../constants/routes";
import Skeleton from "../common/Skeleton";
import { commonActions } from "../../redux/features/common/commonSlice";
import { useAppDispatch } from "../../redux/hooks";

const UnderMaintenance = (props: any) => {

    const { showSnackbar } = props;

    const [t, i18n] = useTranslation();
    const [userStatus, setUserStatus] = useState <any>();
    const [skeleton, setSkeleton] = useState(true);
    const dispatch = useAppDispatch();


    useEffect(()=>{
        GetMaintenancePage();
    },[]);

    const GetMaintenancePage = () => {
        const isHitDummyURL = false;
        new UnderMaintenanceService({}, isHitDummyURL)
            .UnderMaintenanceGetService()
            .then((res: any)=>{
                setUserStatus(res);
                dispatch(commonActions.IS_MAINTENANACE(res?.maintenanceMode));
                (res?.maintenanceMode=="false")? window.location.href = ROUTES.INITIAL_PAGE :  null;
                setSkeleton(false);
            })
            .catch((err)=>{
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };
 
    return (
        <>
         
            <Container>
                { skeleton ? (<Skeleton  varient="rectangular"   width={0}  height={60}  count="10"  my={0} /> ) :(
                    <Grid container className="maintanance_page">
                        <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
                            <Grid className="under_maintanance"> 
                                <img  alt="cainz"src={imagePath("logo_cainz.svg")}/>
                                <Box>
                               
                                    <Typography component="h3">{t("under_maintenance.we_are_under_maintenance")}</Typography>
                                    <Typography sx={{my:2}}>{t("under_maintenance.we_apologize_for_the_inconvenience")}</Typography>
                                    {/* <Typography>{t("under_maintenance.the_maintenance_period_may_be_extended")}</Typography> */}
                                </Box>
                                <Box className="maintenance_date">
                                    {userStatus?.maintenanceModeFrom !=""?
                                        <Typography component="h4">{userStatus?.maintenanceModeFrom} -  <Typography component="span" className="maintenance_mode_to">{userStatus?.maintenanceModeTo}</Typography></Typography>
                                        :""}
                              
                                </Box>

                                <Typography sx={{wordWrap:"break-word", whiteSpace:"pre-line"}}>{userStatus?.maintenanceModeMessage}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} xl={6} lg={6} md={6} sm={12}>
                            <img className="under_img" alt="cainz"src="./assets/images/undermaintenance.png"/>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
};

export default UnderMaintenance;
