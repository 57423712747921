import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import ProfileService from "../services/ProfileService";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { userActions } from "../../../redux/features/user/userSlice";
import RichTextEditor from "../../common/RichTextEditor";
import { SnackbarAlert } from "../../common/SnackbarAlert";

const FAQs = (props: any) => {

    const { showSnackbar } = props;

    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const faq = useAppSelector((state) => state.user.userDetails?.store?.[0].policy?.faq) ?? null;
    const [alertMessage, setAlertMessage] = useState<string>();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [success, setSuccess] = useState(false);

    const saveFaq = (data: string) => {
        dispatch(userActions.SAVE_FAQ(data));
        const payload = { faq: data };
        const isHitDummyURL = false;

        new ProfileService(payload, isHitDummyURL).sellerBussinessInfo().then(res => {
            showSnackbar(res, true);
        }).catch(err => {
            showSnackbar(err?.message, false);
        });

    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            {/* Page header title */}
             
            <HeaderTitle title={t("profile.faqs")}/>

            {/* Page header title */}

            <Container>
                <Typography variant="h6" sx={{ my: 2 }}>
                    {t("profile.faqs")}
                </Typography>
                <RichTextEditor data={faq} saveData={saveFaq} />
                <SnackbarAlert
                    openSnackBar={openSnackBar}
                    message={alertMessage}
                    success={success}
                    handleCloseSnackBar={handleCloseSnackBar}
                />
            </Container>
        </>
    );
};

export default FAQs;
