import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";

class OrderInfoService  {
    private payload: object;
    private isHitDummyURL: boolean;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
    }

    async orderInfo() {
        let orderCard;
        this.isHitDummyURL && API.HIT_DUMMY_URL
            ? (orderCard = await new AxiosUtils(
                `${mockAPIPath.ORDER_INFO}`,
                this.payload
            ).getNew())
            : (orderCard = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ORDER_INFO}`,
                this.payload
            ).getNew());
        return orderCard;
    }
}

export default OrderInfoService;

