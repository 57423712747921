import React, { FC } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
interface headerProps {
  title: any;
}
const HeaderTitle: FC<headerProps> = (props: headerProps): JSX.Element => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Container sx={{ py:2}}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
                        <Typography variant="h4">{props.title}</Typography>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ py: 2 }}>
                            <Typography sx={{ borderBottom: "3px solid red", width:"50px" }}></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default HeaderTitle;
