import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import LatestSellerModel from "../../../models/SellerReports/LatestSellerReportsModel";
import CONSTANTS, { LIST_LIMIT } from "../../../constants/constants";
import { BulkImageUploadReportWithPaginationType } from "../../../interfaces/bulkImageUploadReportInterface";
import { BulkImageUploadLatestReportType } from "../../../interfaces/bulkImageUploadLatestReportInterface";
class SellerReportServices {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async SellerReportsData(page_id: number, limit= LIST_LIMIT.BULK_PRODUCT_UPLOAD_REPORTS, appliedFilter = 0) {
    // let SellerInfosList;
        let response;
        if (appliedFilter == CONSTANTS.ALL_REPORTS_FILTER) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        }
        if (appliedFilter == CONSTANTS.UPLOADED_REPORTS_FILETR) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&status=${CONSTANTS.UPLOADED_REPORTS_STATUS}&error=false&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        }
        if (appliedFilter == CONSTANTS.PENDING_REPORTS_FILTER) {
            response = await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SELLER_INFO_REPORTS}?page=${page_id}&status=${CONSTANTS.UPLOADED_REPORTS_STATUS}&error=true&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        }

        if(response?.data?.success) {
            const { data, totalPages, currentPage, currentTotalCount, totalCount } = response.data.data;
            const bulkImageUploadReportData : BulkImageUploadReportWithPaginationType = {
                bulkImageUploadReports: data,
                pagination: {                  
                    totalPages,
                    currentPage,
                    currentTotalCount,
                    totalCount
                }
            };
            return bulkImageUploadReportData;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }   
    }

    async LatestSellerReportsData() {
        const response = await new AxiosUtils(
            `${API.BASE_URL}${serverAPIPath.LATEST_SELLER_INFO_REPORTS}`,
            this.payload,
            this.headers
        ).get();
        if(response?.data?.success) {
            const bulkImageUploadLatestReportData : BulkImageUploadLatestReportType = response.data.data;
            return bulkImageUploadLatestReportData;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }   
    }
    async dowloadImageErrorReport(id: any) { 
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ERROR_DOWNLOAD}${id}`, this.payload, this.headers).get();
        let downloadCsvFile: string;
        if(response.data.success) {
            downloadCsvFile = response.data.data;
            return downloadCsvFile;
        }
        else {
            throw new Error(response.data.message);
        }
        // return downloadCsvFile;

    }

}

export default SellerReportServices ;
