import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, TextField, Box, Button, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
// import OTPService from "../../../Auth/service/OTPService";
// import AuthService from "../../service/AuthService";
import { useNavigate } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Typography from "@mui/material/Typography";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { ROUTES } from "../../../constants/routes";
import OTPService from "../../Auth/services/OTPService";
import ExhibitionUserListService from "../service/ExhibitionUserListService";
import CONSTANTS from "../../../constants/constants";
// import { ROUTES } from "../../../../constants/routes";

const UpdateEmail = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    /* Language translate function*/
    const [t, i18n] = useTranslation();
    /* Language translate function*/

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [check, setCheck] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successAlert, setSuccessAlert] = useState(false);

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("update_email.please_enter_valid_email"),
                max: t("invitation_page.max_80_char"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    // validation

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "email" ? setEmail(value) : null;
        validator.current.hideMessageFor(name);
    };

    const generateOTP = () => {
        const payload = { email, otpType : CONSTANTS.OTP_EMAIL_TYPE};
        const isHitDummyURL = false;
        new OTPService(payload, isHitDummyURL)
            .generate()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res, true);
                navigate(ROUTES.EMAIL_OTP, { state: { email: email } });
            })
            .catch((err) => showSnackbar(err?.message, false));
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email };
            const isHitDummyURL = false;
            new ExhibitionUserListService(payload, isHitDummyURL)
                .availableEmail()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res.message, true);
                    generateOTP();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };


    return (
        <>
            {/* ForgotPassword page starts here */}
            <Container>
                <Grid container spacing={4}>
                    {/* offset grid with column*/}
                    <Grid item xs={12} md={3}></Grid>
                    {/* main grid column! */}
                    <Grid item xs={12} md={6}>
                        <Box className="mt_300">
                            <Grid item xs={12} md={12}>
                                <Grid>
                                    <Typography
                                        variant="h6"
                                        className="text_center"
                                        sx={{ py: 2 }}
                                    >
                                        {t("update_email.email_address")}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="email"
                                        value={email}
                                        name="email"
                                        label={t("update_email.email_address")}
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={error ? true : false}
                                        helperText={error}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("email", email, [
                                        "required",
                                        { max: 80 },
                                        "email",
                                    ])}

                                    <Grid item xs={12} md={12} sx={{ my: 2 }}>
                                        <Button fullWidth onClick={handleSubmit}>
                                            {t("update_email.next")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {/* offset grid with column*/}
                    <Grid item xs={12} md={3}></Grid>
                </Grid>
            </Container>
            {/* ForgotPassword page ends here */}
        </>
    );
};

export default UpdateEmail;
