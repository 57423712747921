import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    Divider,
    Checkbox,
    fabClasses,
    TextField, InputAdornment,
    IconButton
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import CONSTANTS, { LIST_LIMIT, OMS_CONSTANTS, PARAMS_CONSTANTS } from "../../../constants/constants";
import OmsManageOrderService from "../service/OmsManageOrderService";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
// import OmsFilter from "../OmsUnshipped/OmsUnshippedFilter";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import omsShippedService from "../service/omsShippedService";
import { commonActions } from "../../../redux/features/common/commonSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";
import Utility from "../../../utils/Utility";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SimpleReactValidator from "simple-react-validator";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import jaLocale from "date-fns/locale/ja";
import CommonButton from "../../common/Button";
import { truncate } from "fs/promises";
import DownloadButton from "../../common/DownloadBtn";
import { OMSConfig } from "../config/OMSConfig";
import Cookies from "../../../utils/Cookies";
import { API } from "../../../config";
import packagingSlipService from "../service/packagingSlipService";



const Omsshipped = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const utility = new Utility;
    const shippedFilter = useAppSelector((state: any) => state?.common?.backOrderFilter);
    const omsCurrentPage = useAppSelector((state: any) => state.common.shipped);
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const defaultLimit = LIST_LIMIT.LIMIT_15;
    const firstPage = CONSTANTS.FIRST_PAGE;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const [shippedData, setShippedData] = useState<any>([]);
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(omsCurrentPage?.currentPage ?? 1);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    // const [limit, setLimit] = useState(omsCurrentPage?.limit);
    const [limit, setLimit] = useState(!perPageLimit ? defaultLimit : perPageLimit);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [, forceUpdate] = useState(0);
    const [dateRang, setDateRang] = useState<any>(shippedFilter?.dateRang ? shippedFilter?.dateRang : 7);
    const [shipDate, setShipDate] = useState(shippedFilter?.shipDate ? shippedFilter?.shipDate : "ASC");
    const [search, setSearch] = useState<string>(shippedFilter?.search ? shippedFilter?.search : "");
    const [valueFrom, setValueFrom] = React.useState<Date | null>(shippedFilter?.valueFrom ? shippedFilter?.valueFrom : null);
    const [valueTo, setValueTo] = React.useState<Date | null>(shippedFilter?.valueTo ? shippedFilter?.valueTo : null);
    const [viewCustom, setViewCustom] = useState<boolean>(shippedFilter?.viewCustom ? shippedFilter?.viewCustom : false);
    const [fromParam, setFromParam] = useState<any>(shippedFilter?.fromParam ? shippedFilter?.fromParam : "");
    const [toParam, setToParam] = useState<any>(shippedFilter?.toParam ? shippedFilter?.toParam : "");
    const [filterChange, setFilterChange] = useState(false);
    const [statusCode, setStatusCode] = useState<any>(
        shippedFilter?.statusCode ? shippedFilter?.statusCode : ""
    );

    const [packagingSlipData, setPackagingSlipData] = useState([]);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_SHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_CANCEL({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_REFUND({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);


    useEffect(() => {
        forceUpdate(1);
    });



    const orderIdCompare: any[] = [];
    const [checked, setChecked] = useState(new Array(limit).fill(false));
    const [disabled, setDisabled] = useState<boolean>(true);


    const handleCheckAllChange = (e: any) => {
        e.target.checked == true
            ? setChecked(new Array(i).fill(true))
            : setChecked(new Array(i).fill(false));
    };

    const handleCheckboxChange = (index: any) => (event: any) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };
    // function onSumbit() {
    //     navigate (ROUTES.SHIPPING_MULTIPLE_ORDER);
    // }

    const navigate = useNavigate();

    useEffect(() => {
        omsShippedData(currentPage, limit);
        totalPages != 0 ? setShippedData(currentPage) : null;

    }, []);

    const omsStoreId = useAppSelector((state) => state.common.loggedInStoreId);


    useEffect(() => {
        if (isInvitationSent || dateRang || shipDate) {
            if (filterChange) {
                omsShippedData(firstPage, limit);
            }
        }
    }, [isInvitationSent, dateRang, shipDate]);

    const onsearchApicall = () => {
        if (filterChange || search !== "") {
            omsShippedData(firstPage, limit);
        }
    };

    const propItem = [
        onsearchApicall, search
    ];
    lazyTypingUtils(propItem);

    const omsShippedData = (currentPage: number, limit: number) => {
        setFilterChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new omsShippedService(payload, isHitDummyURL)
            .omsShippedList(currentPage, search.trim(), dateRang, toParam, fromParam, shipDate, OMS_CONSTANTS.STATUS_SHIPPED, limit)
            .then((res) => {
                if (res?.pagination?.totalCount >= 0) {
                    setSkeleton(false);
                }
                // handleLoader(true);
                // showSnackbar(res, true);
                setShippedData(res.omsShippedLogs);
                dispatch(commonActions.SAVE_ORDER_DATA(res?.omsShippedLogs));
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
                dispatch(commonActions.IS_SHIPPED({ currentPage: currentPage, limit: limit }));
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };


    const handleRefund = (id: number, showModels = PARAMS_CONSTANTS.STATUS_ONE) => {
        navigate(`${ROUTES.REFUND_ORDER}/${id}`);
    };

    const handleSlip = (orderId: any) => {
        const orderIdData = [];
        orderIdData.push(orderId);
        dispatch(commonActions.SAVE_ORDER_ID(orderIdData));
        window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
    };

    const handleAllSlip = (checkList: any) => {
        const orderId2: any = [];

        for (let index = 0; index < checkList.length; index++) {
            if (checkList[index]) {
                orderId2.push(orderIdCompare[index]);
            }
        }
        const orderId = orderId2.filter((item: any) => item != undefined);

        if (checked.filter((e) => e == true).length > 0) {
            dispatch(commonActions.SAVE_ORDER_ID(orderId2));
            window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
        }
        else {
            alert("Please Select at least one order");
        }
    };


    const handleSelectRange = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        if (event.target.value == "custom") {
            setViewCustom(true);
        } else {
            setViewCustom(false);
            setFromParam("");
            setToParam("");
            setValueFrom(null);
            setValueTo(null);
            setDateRang(event.target.value);
        }
    };

    const handleSelectShipDate = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setShipDate(event.target.value);
    };


    const handleSearchChange = (e: any) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        const { value } = e.target;
        setSearch(value);
    };

    const handleSumbit = (id: number) => {
        navigate(`${ROUTES.OMS_CONFIRM_SHIPMENT}/${id}`);
    };
    const handleDateTo = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };
    const handleDateFrom = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleSubmit = () => {
        if (validator.current.allValid()) {
            setDateRang("");
            if (dateRang == "") {
                const data = {
                    search,
                    shipDate,
                    dateRang,
                    fromParam,
                    toParam,
                    viewCustom,
                    valueFrom,
                    valueTo,
                    statusCode
                };
                dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
                omsShippedData(currentPage, limit);
            }
        }
        else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        const data = {
            search,
            shipDate,
            dateRang,
            fromParam,
            toParam,
            viewCustom,
            valueFrom,
            valueTo,
            statusCode
        };
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
    }, [search, shipDate, dateRang]);

    const resetAllFilter = () => {
        setFilterChange(true);
        setViewCustom(false);
        setFromParam("");
        setToParam("");
        setValueFrom(null);
        setValueTo(null);
        setDateRang("7");
        setShipDate("ASC");
        setSearch("");
    };
    const shouldDisableDateTo = (date: any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date: any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    let i = 0;

    const singleOrderPackingSlip = async (id: any) => {
        handleLoader(true);
        await new packagingSlipService().refreshAccessToken();
        const selectedOrders = orderIdCompare.filter((item: any) => item === id).toString();
        const screenWidth = window.screen.width;
        const payload = {};
        const isHitDummyUrl = false;
        new packagingSlipService(payload, isHitDummyUrl)
            .packingSlip(
                selectedOrders
            )
            .then(async (res: any) => {
                const pdfWindow = window.open(res, "", `width=1200,height=1000,left=${screenWidth - 1000}`);
                pdfWindow?.document.close();
                pdfWindow?.focus();
                setTimeout(() => {
                    pdfWindow?.print();
                }, 2000);
                handleLoader(false);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };


    const multiOrderPackingSlip = async() => {
        handleLoader(true);
        await new packagingSlipService().refreshAccessToken();
        const selectedOrders = checked.map((item: any, index: number) => checked[index] && orderIdCompare[index]).filter((item: any) => item !== false).toString();
        const screenWidth = window.screen.width;
        const payload = {};
        const isHitDummyUrl = false;
        new packagingSlipService(payload, isHitDummyUrl)
            .packingSlip(
                selectedOrders,
                limit
            )
            .then(async (res: any) => {
                const pdfWindow = window.open(res, "", `width=1200,height=1000,left=${screenWidth - 1000}`);
                pdfWindow?.document.close();
                pdfWindow?.focus();
                setTimeout(() => {
                    pdfWindow?.print();
                }, 2000);
                handleLoader(false);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };

    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        {/* {shippedData.length == 0?<Skeleton
                            varient="rectangular"
                            width={0}
                            height={484}
                            count="20"/> : */}

                        <Box className="oms_unshipped_filter">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ px: 2 }}>

                                    <Typography className="oms-shipped-text"><Box component="span" className="oms_text_margin">{totalCount}</Box> {t("oms.order_in_last")} {dateRang} {t("oms.days")}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        // id="input-with-icon-textfield"
                                        label={t("oms.search_by_order_id")}
                                        onChange={handleSearchChange}
                                        // onKeyUp={handleKeyPress}
                                        value={search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} md={4} lg={2}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label" >
                                            {t("oms.order_confirmation_date")}
                                        </InputLabel>
                                        <Select fullWidth
                                            className="oms-tab-filters"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={viewCustom ? "custom" : dateRang}
                                            label={t("oms.order_confirmation_date")}
                                            onChange={handleSelectRange}
                                        >
                                            <MenuItem value={7}>{t("oms.last_7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("oms.last_30_days")}</MenuItem>
                                            <MenuItem value={90}>{t("oms.Last_90_days")}</MenuItem>
                                            <MenuItem value={365}>{t("oms.last_year")}</MenuItem>
                                            <MenuItem value={"custom"}>{t("oms.custom_date_range")}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                    <Box>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.order_date")}
                                            </InputLabel>
                                            <Select fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={shipDate}
                                                label={t("oms.order_date")}
                                                onChange={handleSelectShipDate}
                                            >
                                                <MenuItem value="DESC">{t("oms.descending")}</MenuItem>
                                                <MenuItem value="ASC">{t("oms.ascending")}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3} md={2} lg={2} className="reset_btn">
                                    <Button
                                        variant="contained"
                                        sx={{ textTransform: "capitalize" }}
                                        onClick={resetAllFilter}
                                        className="oms_submit-btn"
                                    >
                                        {t("pr.reset")}
                                    </Button>
                                </Grid>



                                {
                                    viewCustom ? (
                                        <>
                                            <Grid item xs={0} sm={0} md={1} lg={6} ></Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} className="oms-date-picker">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthAndYear: "yyyy年MM月" }} adapterLocale={jaLocale}>
                                                    <Grid className="oms_date_picker_grid">
                                                        <DatePicker
                                                            // className="date-picker-default"
                                                            label={t("pr.from")}
                                                            value={valueFrom}
                                                            inputFormat="yyyy/MM/dd"
                                                            onChange={handleDateFrom}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                        style: { pointerEvents: "none" },
                                                                    }}

                                                                />)}
                                                            autoFocus={true}
                                                            disableFuture
                                                            shouldDisableDate={shouldDisableDateFrom}
                                                            // maxDate={valueTo}  
                                                            showToolbar={false}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    // The actions will be the same between desktop and mobile
                                                                    actions: []
                                                                }
                                                            }}

                                                        />
                                                        {validator.current.message("from", valueFrom, [
                                                            "required",
                                                        ])}

                                                    </Grid>

                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }}>
                                                    <Grid className="oms_date_picker_grid">
                                                        <DatePicker
                                                            className="oms-date-picker-default"
                                                            label={t("pr.to")}
                                                            value={valueTo}
                                                            inputFormat="yyyy/MM/dd"
                                                            onChange={handleDateTo}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                        style: { pointerEvents: "none" },
                                                                    }}

                                                                />)}
                                                            autoFocus={true}
                                                            disableFuture
                                                            shouldDisableDate={shouldDisableDateTo}
                                                            // minDate={valueFrom}
                                                            showToolbar={false}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: []
                                                                }
                                                            }}
                                                        />
                                                        {validator.current.message("to", valueTo, [
                                                            "required",
                                                        ])}

                                                    </Grid>
                                                </LocalizationProvider>
                                                <Button
                                                    variant="contained"
                                                    className="oms_submit-btn"
                                                    sx={{ textTransform: "capitalize" }}
                                                    onClick={handleSubmit}
                                                >
                                                    {t("pr.submit")}
                                                </Button>
                                            </Grid>
                                        </>
                                    ) : null
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <DownloadButton route={window.location.pathname} functionToExecute={() => multiOrderPackingSlip()} className="oms_head_btn" btnName={t("packaging_slip.print_the_delivery_note")} disableCondition={checked.filter((e) => e == true).length > 0 ? false : true} />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* } */}
                    </Grid>
                </Grid>
            </Container>

            <Container className="oms_table">
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={253}
                                count="10"
                            />
                        ) : (
                            <TableContainer component={Paper}>


                                <Table aria-label="simple table" sx={{ my: 1 }}>
                                    <TableHead className="oms_head">
                                        <TableRow>
                                            <TableCell>

                                                <Box sx={{ my: 1 }}>
                                                    <Checkbox
                                                        className="oms_head_checkbox"
                                                        checked={
                                                            checked.filter((e) => e == false).length > 0
                                                                ? false
                                                                : true
                                                        }
                                                        disabled={
                                                            shippedData?.length == 0
                                                        }
                                                        onClick={(e) => handleCheckAllChange(e)}
                                                    />

                                                    {t("oms.date")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.order_details")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.product_information")} </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.customer_option")}</Box>
                                            </TableCell>
                                            <TableCell className="order_status">
                                                <Box>{t("oms.order_status")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.action")}</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {shippedData
                                            // .filter((e: any) => e.merchantCode == omsStoreId)
                                            ?.map((item: any, idIndex: number) => {
                                                const products = item?.products;
                                                orderIdCompare.push(item.orderId);
                                                return (
                                                    <TableRow key={idIndex}>
                                                        {/* {!orderIdCompare.includes(item.orderId) ? ( */}
                                                        <TableCell>
                                                            <Box className="oms_table_box">
                                                                <Typography
                                                                    className={"order_date_checkbox " + idIndex}>
                                                                    <Checkbox
                                                                        checked={checked[i]}
                                                                        onChange={handleCheckboxChange(i++)}
                                                                    />
                                                                    {/* {<Checkbox checked={checkAll} onClick={(e)=>handleChange(e)}/> } */}
                                                                    <Box sx={{display: "inline-grid"}}>
                                                                        <Typography>
                                                                            {t("oms.order_date")} :
                                                                        </Typography>
                                                                        <Typography>
                                                                            {item.orderDate}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {t("oms.order_confirmation_date")} :
                                                                        </Typography>
                                                                        <Typography>
                                                                            {item.orderConfirmationDate}
                                                                        </Typography>
                                                                    </Box>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        {/* ) : null} */}
                                                        {/* {shippedData
                                                        .filter(
                                                            (e: any) =>
                                                                // e.merchantCode == omsStoreId &&
                                                                e.orderId == item.orderId &&
                                // e.merchantCode == item.merchantCode &&
                                !orderIdCompare.includes(e.orderId)
                                                        )
                                                        ?.map((store: any, storeIndex: number) => {
                                                            orderIdCompare.push(item.orderId);
                                                            return storeIndex == 0 ? ( */}
                                                        <TableCell colSpan={5}>
                                                            <Table>
                                                                <TableBody>

                                                                    <TableRow className="CEC-border-none">
                                                                        <TableCell>
                                                                            <Box className="oms_table_box">
                                                                                <Typography>
                                                                                    {t("oms.order_id")} :
                                                                                </Typography>
                                                                                <Typography className="text_wrap_data">
                                                                                    <Link to={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${item?.orderId}`} className="Order_id_link">
                                                                                        {item?.orderId}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {t("oms.buyer_name")} :
                                                                                </Typography>
                                                                                <Typography className="text_wrap_data">
                                                                                    {item?.ordererName} {item?.nameOfOrderer}
                                                                                </Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell

                                                                        >
                                                                            <Table>
                                                                                <TableBody>
                                                                                    {/* {shippedData
                                                                                    .filter(
                                                                                        (e: any) =>
                                                                                            e.orderId == store.orderId
                                                                                        //   &&e.merchantCode == omsStoreId
                                                                                    )
                                                                                    ?.map(
                                                                                        (
                                                                                            product: any,
                                                                                            productIndex: number
                                                                                        ) => {
                                                                                            return ( */}
                                                                                    {products?.map((item: any, productIndex: any) =>
                                                                                        <TableRow
                                                                                            key={productIndex}
                                                                                            className="table_border"
                                                                                        >
                                                                                            <TableCell className="table_cell_padding">
                                                                                                <Box className="oms_table_box">
                                                                                                    <img
                                                                                                        src={
                                                                                                            item?.image
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {
                                                                                                            item?.productName
                                                                                                        }
                                                                                                    </Typography>

                                                                                                    <Typography>
                                                                                                        {t("oms.cpid")} : {item?.cainzProductCode}
                                                                                                    </Typography>


                                                                                                    <Typography>
                                                                                                        {t("oms.quantity")} : {item?.salesVolume}


                                                                                                    </Typography>
                                                                                                    <Typography>
                                                                                                        {t("oms.shipped_quantity")} : {item?.shippedQuantity.split("|").filter((item: any) => item !== "0").length > 0 ? item?.shippedQuantity.split("|").filter((item: any) => item !== "0").join(",") : "0"}
                                                                                                    </Typography>



                                                                                                    <Typography>
                                                                                                        {t("oms.price_subtotal")} : &yen;{
                                                                                                            new Utility().numberWithCommas(item?.productPriceIncludingTaxSubtotal)
                                                                                                        }


                                                                                                    </Typography>
                                                                                                    {
                                                                                                        productIndex + 1 == products?.length ?
                                                                                                            "" :
                                                                                                            <Divider sx={{ mt: 3 }} />
                                                                                                    }
                                                                                                </Box>
                                                                                            </TableCell>

                                                                                            <TableCell>
                                                                                                <Box className="oms_table_box">
                                                                                                    <Typography> {t("oms.delivery_by_date")} : </Typography>  <Typography>{item?.productDeliveryDate}</Typography>
                                                                                                    <Typography> {t("oms.ship_by_date")} : </Typography><Typography>{item?.shipmentCompletionDate.split("|").filter((item: any) => item !== "").join(",")}</Typography>
                                                                                                </Box>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                    }

                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Button
                                                                                className="unshipped order_status"
                                                                                variant="contained"
                                                                            >
                                                                                {t("oms.shipped")}
                                                                            </Button>

                                                                            <Typography sx={{width:"50%"}} >{t("oms.order_shipment_completion_date")} : {item?.shipmentCompletionDate} </Typography>
                                                                            {item?.refundApplicationDate !== "" ?
                                                                                <Button
                                                                                    className="unshipped order_status bg_danger"
                                                                                    variant="contained"
                                                                                >
                                                                                    {t("oms.refunded")}
                                                                                </Button>
                                                                                : null}
                                                                            <Typography></Typography>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Box className="table_button">
                                                                                <CommonButton route={window.location.pathname} functionToExecute={() => handleSumbit(item?.orderId)} className="unshipped" btnName={t("oms.edit_shipment")} disableCondition={false} />


                                                                                {/* <Button
                                                                                    className="unshipped"
                                                                                    variant="contained"
                                                                                    onClick={() =>
                                                                                        handleSumbit(item?.orderId)
                                                                                    }
                                                                                >
                                                                                    {t("oms.edit_shipment")}
                                                                                </Button> */}
                                                                                {/* <Button
                                                                                    onClick={() =>
                                                                                        handleSlip(item?.orderId)
                                                                                    }
                                                                                    className="unshipped packing_slip_btn"
                                                                                    variant="contained"
                                                                                >
                                                                                    {t("oms.print_packing_slips")}
                                                                                </Button> */}

                                                                                <DownloadButton route={window.location.pathname} functionToExecute={() =>singleOrderPackingSlip(item?.orderId)} className="unshipped" btnName={t("packaging_slip.print_the_delivery_note")} disableCondition={false}/>
                                                                                <CommonButton route={window.location.pathname} functionToExecute={() => handleRefund(item?.orderId)} className="unshipped bg_danger" btnName={t("oms.refund_order")} disableCondition={false} />

                                                                                {/* <Button
                                                                                    className="unshipped bg_danger"
                                                                                    variant="contained"
                                                                                    onClick={() => handleRefund(item?.orderId)}
                                                                                >
                                                                                    {t("oms.refund_order")}
                                                                                </Button> */}
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                        {/* ) : null;
                                                        })} */}
                                                    </TableRow>
                                                );
                                            })}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Typography align="center" variant="h6">
                            {shippedData?.length == 0 ? t("oms.no_order_found") : ""}
                        </Typography>

                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={omsShippedData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default Omsshipped;


