import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import HeaderTitle from "../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import TransactionService from "./services/transactionService";
import Pagination from "../common/Pagination";
import { DATE_CONFIG, LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";
import SimpleReactValidator from "simple-react-validator";
import Skeleton from "../common/Skeleton";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import MUIDataTable from "mui-datatables";
import Utility from "../../utils/Utility";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import jaLocale from "date-fns/locale/ja";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import { useAppSelector } from "../../redux/hooks";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadButton from "../common/DownloadBtn";



const PaymentReports = (props: any) => {

    const { showSnackbar } = props;

    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const utility = new Utility();


    const firstPage = 1;
    const [transactionList, setTransactionList] = useState<any>();
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit );
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedValue, setSelectedValue] = useState("a");
    const [customDateModel, setCustomDateModel] = useState(true);
    const [pastNumberModel, setPastNumberModel] = useState(false);
    const [settlementModel, setSettlementModel] = useState(false);
    const [valueFrom, setValueFrom] = useState<Date | null>(null);
    const [valueTo, setValueTo] = useState<Date | null>(null);
    const [transactionType,setTransactionType]=useState("");
    const [transactionStatus,setTransactionStatus]=useState("");
    const [search, setSearch] = useState<string>("");
    const [settlementPeriod, setSettlementPeriod] = useState<string>("");
    const [depositDate, setDepositDate] = useState<string>("");
    const [pastNoOfDays, setPastNoOfDays] = useState<string>("");
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const [skeleton, setSkeleton] = useState(true);
    const [resetData, setResetData] = useState(false);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");


    const apiData: any[][] = [];

    const[t] = useTranslation();
    const [, forceUpdate] = useState(0);

    useEffect(() => {
        forceUpdate(1);
    });

    const validatorCustomDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    const validtorSettlement = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    const validatorPastDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );


    useEffect(() => {    
        if(depositDate || transactionType || transactionStatus || resetData ||colFilter||direction){
            transactionData(firstPage, limit); 
        } 
    }, [ depositDate, transactionType, transactionStatus, resetData,colFilter,direction]);

    useEffect(() => {      
        transactionData(firstPage, limit); 
    }, []);

    
    const onsearchApicall = () =>{
        if(isSearchChange){
            transactionData(firstPage, limit); 
        } 
    };

    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    const transactionData = (currentPage: number, limit: any) => {
        setResetData(false);    
        setIsSearchChange(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new TransactionService(payload, isHitDummyURL)
            .transactionList(currentPage, limit, search,transactionType, transactionStatus,pastNoOfDays,toParam,fromParam,settlementPeriod,depositDate,colFilter,direction)
            .then((res) => {   
                if(res?.pagination?.totalCount>=0){                   
                    setSkeleton(false);
                }
                setLimit(limit);
                setTransactionList(res);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };




    const downloadSalsesHistoryData = () => {
        setLoading(true);
        showSnackbar(t("bulk_product_index.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        new TransactionService(payload, isHitDummyURL)
            .downloadPaymentReportList( search,transactionType, transactionStatus,pastNoOfDays,toParam,fromParam,settlementPeriod,depositDate,colFilter,direction)
            .then((res: any) => {
                handleOnExport(res?.transactionsLogs);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setLoading(false);
                setSkeleton(false);
            });
    };


    const handleSettlement = (event: any) => {
        setSelectedValue(event.target.value);
        setCustomDateModel(false);
        setPastNumberModel(false);
        setSettlementModel(true);
        setValueFrom(null);
        setValueTo(null);
        setPastNoOfDays("");
        setFromParam("");
        setToParam("");
       
    };

    const handlePastNumber = (event: any) => {
        setSelectedValue(event.target.value);
        setCustomDateModel(false);
        setPastNumberModel(true);
        setSettlementModel(false);
        setValueFrom(null);
        setValueTo(null);
        setSettlementPeriod("");
        setFromParam("");
        setToParam("");
    };

    const handleCustomRange = (event: any) => {  
        setSelectedValue(event.target.value);
        setCustomDateModel(true);
        setPastNumberModel(false);
        setSettlementModel(false);
        setPastNoOfDays("");
        setSettlementPeriod("");  
    };
    const configurationDate = new Date(transactionList?.firstSalesDate);
    const currentDate = new Date();
    const diffInConfigurationDate = (currentDate.getFullYear() - configurationDate.getFullYear()) * 12 +
    (currentDate.getMonth() - configurationDate.getMonth());
    const settlement_period_filter_options = [];
    const deposit_date_filter_option=[];

    for (let i = diffInConfigurationDate+2; i >=0 ; i--) {
        const month = configurationDate.getMonth() + i;
        const year = configurationDate.getFullYear();
        if(i>=2){
            const startDateForDepositDate = new Date(year, month, 1);
            const formattedDepositDate = utility.getFormattedDateTime(startDateForDepositDate, DATE_CONFIG.GET_DATE_20);
            deposit_date_filter_option.push(formattedDepositDate);
        }
        if(i<=diffInConfigurationDate){
            const startDate = new Date(year, month, 1);
            const endDate = new Date(year, month + 1, 0);
            const formattedSettlementDate = `${utility.getFormattedDateTime(startDate)}-${utility.getFormattedDateTime(endDate)}`;
            settlement_period_filter_options.push(formattedSettlementDate);
        }
    }
    const handleTransactionType = (event: SelectChangeEvent) => {
        setTransactionType(event.target.value);
    };
    const handleTransactionStatus = (event: SelectChangeEvent) => {
        setTransactionStatus(event.target.value);
    };
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    const handlePastNoOfDays = (e:any)=>{
        const { value } = e.target;
        setPastNoOfDays(value);
    };
    const handleSettlementPeriod = (e:any)=>{
        const { value } = e.target;
        setSettlementPeriod(value);
    };
    const handleDepositDate = (e:any)=>{
        const { value } = e.target;
        setDepositDate(value);
    };
    const handleDateFrom = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleDateTo = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };



    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            downloadData.push({
                "日付":item?.date?.split("T")[0],
                "種別":item?.kinds,
                "ステータス":item?.deposit_status,
                "注文番号":item?.order_id,
                "受注金額":item?.total_order_amount,
                "ポイント利用額":item?.point_usage_amount,
                "ポイント付与額":item?.total_point_awarded,
                "Cainz手数料額":item?.cainz_fee,
                "売上額":item?.seller_seles_amount,     
                "入金処理日":item?.sales_transfer_date || item?.refund_transfer_date,
                "入金予定日":item?.transfer_execute_date

            });
        });
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(downloadData);
        // XLSX.utils.book_append_sheet(wb, ws, "売上一覧");
        // XLSX.writeFile(wb, "売上一覧.xlsx");
        const fileName="売上一覧.xlsx";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.SALES_LIST);
        setLoading(false);
    };

    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName = changedColumn == "Date" || changedColumn=="日付" ? "date": changedColumn == "Order Number" || changedColumn == "注文番号" ? "order_id":changedColumn == "Scheduled Payment Date" || changedColumn=="入金予定日" ? "transfer_execute_date": changedColumn == "Payment Day" || changedColumn=="入金処理日" ? "payment_day":"";
          
        setColFilter(columnName);

    };
    


    const columns = [
        {
            name: t("pr.date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="date" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>       
                        </Box>
                    );
                },       
            },
        },
    
        {
            name: t("pr.kind"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("pr.deposit_status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("pr.order_number"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="order_id" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>   
                        </Box>
                    );
                },  
            },
        },
      
        {
            name: t("pr.order_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.amount_of_points_used"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },

        {
            name: t("pr.points_used"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },

        {
            name: t("pr.cainz_fee_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.sales_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({className: "text_right"})
            },
        },
        {
            name: t("pr.payment_day"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="payment_day" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>       
                        </Box>
                    );
                }, 
            },
     
        },
        {
            name: t("pr.scheduled_payment_date"),
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={(e)=>handleSorting(data.name)} >
                                {colFilter!="transfer_execute_date" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>   
                        </Box>
                    );
                },  
            },
        },
       
    ];

    const tableNegativeValue = (value: any) => {
        return (

            <Box className={`${value < 0 ? "sales_history_negative_quantity_red " : "sales_history_negative_quantity_black"}`}>{new Utility().numberWithCommas(value)}</Box>
        );
    };

    const tableNegativeValueWidthLInk = (amount: any) => {
        return (
            <Link to ={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${amount}`} target="_blank" className="link_color"><Box className={`${amount < 0 ? "link_color " : "link_color"}`}>{amount}</Box></Link>
        );
    };
  

    const apiTableData = () => {
        {
            transactionList?.transactionsLogs?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    item?.date,
                    item?.kinds,
                    item?.deposit_status ,
                    tableNegativeValueWidthLInk(item?.order_id),
                    tableNegativeValue(item?.total_order_amount),
                    tableNegativeValue(item?.point_usage_amount),
                    tableNegativeValue(item?.total_point_awarded),
                    tableNegativeValue(item?.cainz_fee),
                    tableNegativeValue(item?.seller_seles_amount),
                    item?.sales_transfer_date || item.refund_transfer_date,
                    item?.transfer_execute_date
                );
            });
        }
    };
    apiTableData();


    const handleSubmit = () =>{
        const validator = settlementModel ? 
            validtorSettlement 
            : (pastNumberModel ? 
                validatorPastDate : 
                validatorCustomDate );

        if (validator.current.allValid()) {
            transactionData(firstPage, limit);     
        }
        else {
            validator.current.showMessages();
        }
    };

    const resetAllFilter = () =>{
        setSearch("");
        setTransactionStatus("");
        setTransactionType(""); 
        setDepositDate("");
        setCustomDateModel(true);
        setPastNumberModel(false);
        setSettlementModel(false);
        setValueFrom(null);
        setValueTo(null);
        setPastNoOfDays("");
        setFromParam("");
        setToParam("");
        setSettlementPeriod("");
        setSelectedValue("a");
        setResetData(true);    
    };
    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };


    return (

        <>
            <Container className="paymentReport">
                <HeaderTitle title={t("pr.header_title")} />
                <Grid  className="shipment-confirm-order" sx={{ p: 3, backgroundColor: "#f0f2f2" }}  >
                    <Grid container spacing={2} className="sales_list_header">
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.deposit_date")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={depositDate}
                                    onChange={handleDepositDate}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 250,  
                                        },
                                    }}  
                                    input={
                                        <OutlinedInput label={t("pr.deposit_date")}  />
                                    }
                                    // MenuProps={MenuProps}
                                >                                  
                                    {
                                        deposit_date_filter_option?.map((item,index)=>{
                                            return(
                                                <MenuItem
                                                    value={item}
                                                    key={index}
                                                >
                                                    {item}                                   
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.transaction_type")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    input={
                                        <OutlinedInput label={t("pr.transaction_type")} />
                                    }
                                    onChange={handleTransactionType}
                                    value={transactionType}
                                    
                                    // MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        // style={getStyles(quantity, personName, theme)}
                                        value="売上"
                                    >
                                        {t("pr.earnings")}
                                    </MenuItem>
                                    <MenuItem value="返金">{t("pr.refund")}</MenuItem>                                                                  
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("pr.transaction_status")}</InputLabel>
                                <Select
                                    className="paymentReport-dropdown"
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    input={
                                        <OutlinedInput label={t("pr.transaction_status")} />
                                    }
                                    onChange={handleTransactionStatus}
                                    value={transactionStatus}

                                    // MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        // style={getStyles(quantity, personName, theme)}
                                        value="入金予定"
                                    >
                                        {t("pr.expected_payment")}
                                    </MenuItem>
                                    <MenuItem value="返金予定">{t("pr.scheduled_refund")}</MenuItem>
                                    <MenuItem value="入金処理済み">{t("pr.deposited")}</MenuItem>
                                    <MenuItem value="返金処理済み">{t("pr.refunded")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Box >
                                <FormControlLabel className="radio_button_box" checked={selectedValue === "a"} value="a" onChange={handleCustomRange} control={<Radio />} label={t("pr.custom_date_range")} />
                                <FormControlLabel className="radio_button_box" checked={selectedValue === "b"} value="b" onChange={handlePastNumber} control={<Radio />} label={t("pr.past_number_of_days")} />
                                <FormControlLabel className="radio_button_box" checked={selectedValue === "c"} value="c" onChange={handleSettlement} control={<Radio />} label={t("pr.payment_period")} />
                            </Box>
                        </Grid>
                        {
                            settlementModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <FormControl sx={{ mt: 1, width: "100%" }}>
                                        <InputLabel>{t("pr.payment_period")}</InputLabel>
                                        <Select
                                            className="settlement"
                                            labelId="demo-multiple-name-label"
                                            id="settlement"
                                            value={settlementPeriod}
                                            input={
                                                <OutlinedInput label={t("pr.payment_period")} />
                                            }
                                            MenuProps={{
                                                style: {
                                                    maxHeight: 250,  
                                                },
                                            }}                                
                                            onChange={handleSettlementPeriod}
                                            // MenuProps={MenuProps}
                                        >
                                            {
                                                settlement_period_filter_options?.map((item,index)=>{
                                                    return(
                                                        <MenuItem
                                                            value={item}
                                                            key={index} >
                                                            {item}
                                                        </MenuItem>

                                                    );
                                                })
                                            }
                                           
                                        </Select>
                                        {validtorSettlement.current.message("settlement", settlementPeriod, [
                                            "required",
                                        ])}
                                    </FormControl>
                                </Grid>
                            ) : null
                        }
                        {
                            pastNumberModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                    <FormControl sx={{ mt: 1, width: "100%" }}>
                                        <InputLabel>{t("pr.past_number_of_days")}</InputLabel>
                                        <Select
                                            className="pastnum"
                                            labelId="demo-multiple-name-label"
                                            id="pstnum"
                                            value={pastNoOfDays}
                                            input={
                                                <OutlinedInput label={t("pr.past_number_of_days")} />
                                            }
                                            onChange={handlePastNoOfDays}
                                            // MenuProps={MenuProps}
                                        >
                                            <MenuItem
                                                // style={getStyles(quantity, personName, theme)}
                                                value={1}
                                            >
                                                {t("pr.1_day")}
                                            </MenuItem>
                                            <MenuItem value={3}>{t("pr.3_days")}</MenuItem>
                                            <MenuItem value={7}>{t("pr.7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("pr.30_days")}</MenuItem>
                                            <MenuItem value={60}>{t("pr.60_days")}</MenuItem>
                                            <MenuItem value={90}>{t("pr.90_days")}</MenuItem>
                                        </Select>
                                        {validatorPastDate.current.message("pastnum", pastNoOfDays, [
                                            "required",
                                        ])}
                                    </FormControl>
                                </Grid>
                            ) : null
                        }
                        {
                            customDateModel ? (
                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className="paymentReport-datepicker">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }} className="date-picker-main">
                                        <Grid>
                                            <DatePicker
                                                className="valuefrom"
                                                label={t("pr.from")}
                                                value={valueFrom}
                                                inputFormat="yyyy/MM/dd"
                                                onChange={handleDateFrom}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true,
                                                            style: { pointerEvents: "none" },
                                                        }}  

                                                    />)}
                                                autoFocus={true}
                                                disableFuture
                                                // maxDate={valueTo} 
                                                shouldDisableDate={shouldDisableDateFrom}
                                                showToolbar={false}
                                                componentsProps={{
                                                    actionBar: {
                                                        // The actions will be the same between desktop and mobile
                                                        actions: []
                                                    }
                                                }}
                                           
                                            />
                                            {validatorCustomDate.current.message("valuefrom", valueFrom, [
                                                "required",
                                            ])}

                                        </Grid>
                                      
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }}>
                                        <Grid>
                                            <DatePicker
                                                className="valueto"
                                                label={t("pr.to")}
                                                value={valueTo}
                                                inputFormat="yyyy/MM/dd"
                                                onChange={handleDateTo}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true,
                                                            style: { pointerEvents: "none" },
                                                        }}  

                                                    />)}                                         
                                                autoFocus={true}
                                                disableFuture
                                                // minDate={valueFrom}
                                                shouldDisableDate={shouldDisableDateTo}
                                                showToolbar={false}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: []
                                                    }
                                                }}
                                                                                             

                                            />
                                            {validatorCustomDate.current.message("valueto", valueTo, [
                                                "required",
                                            ])}

                                        </Grid>
                                       
                                    </LocalizationProvider>
                                </Grid>
                            ) : null
                        }
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1} className="submit_btn">
                            <Button
                                variant="contained"
                                sx={{textTransform: "capitalize"}}
                                onClick={handleSubmit}
                            >
                                {t("pr.submit")}
                            </Button>
                        </Grid>  
                        <Grid item xs={6} sm={4} md={2} lg={2} xl={2} className="reset_btn">
                            <Button
                                variant="contained"                           
                                sx={{textTransform: "capitalize"}}
                                onClick={resetAllFilter}
                            >
                                {t("pr.reset")}
                            </Button>
                        </Grid>                                   
                    </Grid>          
                </Grid>
            </Container>
            <Container sx={{py:2}}>
                <Grid container>
                    <Grid item xs={10} sm={8} md={6} lg={7} xl={8}>
                        {/* <HeaderTitle title={t("pr.transaction_list")} /> */}
                    </Grid>
                    <Grid item xs={7} sm={5} md={3} lg={3} xl={2} className="pr_search_bar">
                        <TextField
                            // id="input-with-icon-textfield"
                            label={t("pr.search_by_order_id")}                           
                            onChange={handleSearchChange}
                            // onKeyUp={handleKeyPress}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />                       
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={2} xl={2} className="pr_download_btn">
                        <DownloadButton loading={loading} route={window.location.pathname} functionToExecute={downloadSalsesHistoryData} className="" btnName={t("pr.download")} disableCondition={loading || transactionList?.transactionsLogs.length == 0 || !transactionList?.transactionsLogs}/>
                        {/* <Button
                            variant="contained"
                            sx={{textTransform: "capitalize"}}
                            onClick={downloadSalsesHistoryData}
                            disabled={loading || transactionList?.transactionsLogs.length == 0 || !transactionList?.transactionsLogs }
                        >
                            {t("pr.download")}
                            {loading &&  (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Button>   */}
                    </Grid>                   
                </Grid>
                        
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                       
                    <Container className="common_table">
                        <MUIDataTable 
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,
                                // sortOrder: {
                                //     name: colName,
                                //     direction: direction =="desc"? "desc":"asc",
                                // },
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",
                                // onColumnSortChange: (changedColumn, direction) => {
                                //     setDirection(direction);
                                //     setColName(changedColumn);     
                                //     const columnName = changedColumn == "Date" || changedColumn=="日付" ? "date": changedColumn == "Order Number" || changedColumn == "注文番号" ? "order_id":changedColumn == "Scheduled Payment Date" || changedColumn=="入金予定日" ? "transfer_execute_date": changedColumn == "Payment Day" || changedColumn=="入金処理日" ? "":"";
                                //     setColFilter(columnName);
                                // },
                             
                            }}
                       
                        />
                    </Container>
                )} 
               
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={transactionData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default PaymentReports;
