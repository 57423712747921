import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
class CsvfileUpload {
    // xlsxfile() {
    //     throw new Error("Method not implemented.");
    // }
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;

        this.headers = { "Content-Type": "multipart/form-data", "Access-Control-Allow-Origin": "*","access-token": new Cookies("access-token").read() };
    }

    async csvfile(onUploadProgress:any) {

        // let fileupload;
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.FILE_UPLOAD}`, this.payload,this.headers).fileUploadPostRequest(onUploadProgress);
        let fileupload: string;
        if(response?.data?.success==true) {
            fileupload = response?.data?.message;
            return fileupload;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 

    }
}

export default CsvfileUpload;

