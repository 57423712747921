import { API } from "../../../../config";
import AxiosUtils from "../../../../utils/AxiosUtils";
import Cookies from "../../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config";

class VerifyInvitationService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async verifyInviteLink(code:any){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_INVITE_LINK}${code}`, this.payload, this.headers).put();
        let isInviteLinkVerified: string;
        if(response?.data?.success==true) {
            isInviteLinkVerified = response?.data?.message;
            return isInviteLinkVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

}

export default VerifyInvitationService;