import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from "../../../utils/Cookies";
// import jwt_decode from "jwt-decode";
// import { ROUTES } from "../../../constants/routes";

const Page404 = () => {

    // const [params, setParams] = useSearchParams();

    // const navigate = useNavigate();

    // const code = params.get("code");
    // const cookiesRead = new Cookies("access-token").read();

    // useEffect(() => {
    //     if(code && cookiesRead) {
    //         new Cookies("access-token").delete();
    //         navigate(`${ROUTES.ADMIN_AUTH}?code=${code}`);
    //     }
    // });

    return (
        <>
            <Grid container justifyContent={"center"} sx={{py: 30}}>
                <Typography fontSize={50}>404</Typography>
            </Grid>
        </>
    );
};

export default Page404;