import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import  PaymentInformationServices  from "../services/PaymentInformation";
import SimpleReactValidator from "simple-react-validator";

const PaymentInformation = (props: any) => {
    const { login, handleLoader, showSnackbar } = props;
    const { t, i18n } = useTranslation();
    const [credit, setCredit] = useState();
    const [date, setDate] = useState();
    const [cardholder, setCardholder] = useState();
    const [cvv, setCvv] = useState();


    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation

    const UpdatePaymentInformation = () =>{
        if(validator.current.allValid()) {
            handleLoader(true);
            const payload = {credit,date,cardholder,cvv};
            const isHitDummyURL = true;
            new PaymentInformationServices(payload, isHitDummyURL)
                .UpdatePaymentInformation()
                .then((res: any) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                })
                .catch((err: any) => {
                    handleLoader(true);
                    showSnackbar(err?.message, false);
                });

        }else{
            validator.current.showMessages();
        }
        
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        name === "Credit Card"
            ? setCredit(value)
            : name === "Expiry Date"
                ? setDate(value)
                : name === "Cardholder Name"
                    ? setCardholder(value)
                    : name === "Cvv"
                        ? setCvv(value) : null;
    };

    return (
        <>
            {/* Page header title */}
            <HeaderTitle title={t("store_details.payee_information")} />
            {/* Page header title */}

            <Container>
                {/* Payment Information starts here */}
                <Box className="ragistration_box">
                    <Typography variant="h6">
                        {t("store_details.payment_information")}
                    </Typography>
                    <Box className="bg-gray">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    value={credit}
                                    name="Credit Card"
                                    label={t("store_details.credit_card_number")}
                                    onChange={(e) => handleChange(e)}
                                />
                                {validator.current.message("Credit Card", credit, [
                                    "required",
                                            
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    value={date}
                                    name="Expiry Date"
                                    label={t("store_details.month_year")}
                                    onChange={(e) => handleChange(e)}
                                />
                                {validator.current.message("Expiry Date", date, [
                                    "required",
                                            
                                ])}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    value={cardholder}
                                    name="Cardholder Name"
                                    label={t("store_details.cardholder_name")}
                                    onChange={(e) => handleChange(e)}
                                />
                                {validator.current.message("Cardholder Name", cardholder, [
                                    "required",
                                            
                                ])}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    value={cvv}
                                    name="Cvv"
                                    label={t("store_details.cvv")}
                                    onChange={(e) => handleChange(e)}
                                />
                                {validator.current.message("Cvv", cvv, [
                                    "required",
                                            
                                ])}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* Payment Information starts here */}

                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button fullWidth onClick={UpdatePaymentInformation}>{t("store_details.save")}</Button>
                    </Grid>
                </Grid>
                {/*Payee Information starts here */}
            </Container>
        </>
    );
};

export default PaymentInformation;
