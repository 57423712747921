import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Card, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HeaderTitle from "../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import RedeemIcon from "@mui/icons-material/Redeem";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Pending from "./Pending";
import Unshipped from "./Unshipped";
import Shipped from "./Shipped";
import Canceled from "./Canceled";
import Return from "./Return";
import Completed from "./Completed";
import OrderInfoService from "./services/OrderInfoService";
import Skeleton from "../common/Skeleton";

const manage_order = (props: any) => {
    /* Language translate function*/
    const [t, i18n] = useTranslation();
    /* Language translate function*/
    const { handleLoader, showSnackbar } = props;
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        OrderCardInfo();
    }, []);

    const [repotsData, setRepotsData] = useState<any>([]);
    const OrderCardInfo = () => {
        const payload = {};
        const isHitDummyURL = true;
        new OrderInfoService(payload, isHitDummyURL)
            .orderInfo()
            .then((res: any) => setRepotsData(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };

    return (
        <>
            <div className="main">
                <Container sx={{ py: 2 }}>
                    {/* Page header title */}
                    <HeaderTitle title={t("manage_order.manage_orders")} />
                    {/* Page header title */}

                    <Container sx={{ my: 2 }}>
                        <Grid container spacing={2}>
                            {repotsData.length == 0 ? (
                                <Skeleton count={10} height={100} variant="rectangular" my={0} />
                            ) : (
                                repotsData?.map((item: any) => {
                                    return (
                                        <>
                                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                                <Card>
                                                    <Grid container spacing={2} sx={{ px: 2, my: 2 }}>
                                                        <Grid item xs={12} sm={6} md={6} lg={3}>
                                                            <Typography variant="h6">{item.count}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={9}>
                                                            <Typography className="report-carrd-title">
                                                                <Typography variant="h6">{item.name}</Typography>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </>
                                    );
                                })
                            )}
                        </Grid>
                    </Container>

                    <Box>
                        <TabContext value={value}>
                            {/*Tab menu starts here*/}
                            <Container sx={{ py: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box>
                                            <TabList
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab
                                                    icon={<AccessTimeFilledIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.pending")}
                                                    value="1"
                                                    color="primary"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<RedeemIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.unshipped")}
                                                    value="2"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<LocalShippingIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.shipped")}
                                                    value="3"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<HighlightOffIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.canceled")}
                                                    value="4"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<CachedIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.return")}
                                                    value="5"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<CheckIcon />}
                                                    iconPosition="start"
                                                    label={t("manage_order.completed")}
                                                    value="6"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                            </TabList>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                            {/*Tab menu ends here*/}

                            {/*Tab Content starts here*/}
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <Pending
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>
                            {/* Template download Tab ends here*/}

                            {/* Template upload Tab start here*/}
                            <TabPanel value="2" sx={{ p: 0 }}>
                                <Unshipped
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>
                            {/* Template upload Tab ends here*/}

                            {/* Reports Tab start here*/}
                            <TabPanel value="3" sx={{ p: 0 }}>
                                <Shipped 
                                    showSnackbar={showSnackbar}
                                />
                                {/* Table data ends here */}
                            </TabPanel>
                            <TabPanel value="4" sx={{ p: 0 }}>
                                <Canceled
                                    showSnackbar={showSnackbar}
                                />
                                {/* Table data ends here */}
                            </TabPanel>
                            <TabPanel value="5" sx={{ p: 0 }}>
                                <Return 
                                    showSnackbar={showSnackbar}
                                />
                                {/* Table data ends here */}
                            </TabPanel>
                            <TabPanel value="6" sx={{ p: 0 }}>
                                <Completed
                                    showSnackbar={showSnackbar}
                                />
                                {/* Table data ends here */}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default manage_order;
