import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import "../../../theme/scss/style.scss";
import { useTranslation } from "react-i18next";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CurrencyYenOutlinedIcon from "@mui/icons-material/CurrencyYenOutlined";
import ReturnOrderDetail from "../services/ReturnOrderDetail";
import HeaderCard from "../common/HeaderCard";
import Skeleton from "../../common/Skeleton";

const Return = (props:any) => {

    const { showSnackbar }=props;

    const [t, i18n] = useTranslation();
    useEffect(() => {
        returnedService();
    }, []);

    const [returndDetail, setReturnedDetail] = useState<any>([]);

    const returnedService = () => {
        const payload = {};
        const isHitDummyURL = true;
        new ReturnOrderDetail(payload, isHitDummyURL)
            .returndOrder()
            .then((res: any) => setReturnedDetail(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };

    return (
        <>
            <div className="main">
                <Container>
                    <Box className="flex-end" sx={{ my: 4 }}>
                        <Button
                            className="manage_btn"
                            sx={{ mr: 2 }}
                            variant="contained"
                            disabled
                        >
                            {t("manage_order.i_want_to_pick_up")}
                        </Button>
                        <Button className="manage_btn" variant="contained" disabled>
                            {t("manage_order.leave_it")}
                        </Button>
                    </Box>
                </Container>

                {returndDetail.length == 0 ? (
                    <Skeleton count={10} height={100} variant="rectangular" my={0} />
                ) : (
                    returndDetail?.map((item: any) => {
                        return (
                            <>
                                <HeaderCard />
                                <Container className="light-gray">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={2} md={2} lg={2}>
                                            <Box className="order_date">
                                                <Typography>{item.orderDate}</Typography>
                                                <Typography>{item.orderTime}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={2} md={2} lg={2}>
                                            <Box>
                                                <Typography>{t("manage_order.order_id")} :</Typography>
                                                <Typography color="primary">{item.orderId}</Typography>
                                            </Box>

                                            <Box sx={{ mt: 1 }}>
                                                <Typography>{t("manage_order.buyer_name")} :</Typography>
                                                <Typography> {item.buyerName}</Typography>
                                            </Box>

                                            <Box sx={{ mt: 1 }}>
                                                <Typography>
                                                    {t("manage_order.sales_channel")} :
                                                </Typography>
                                                <Typography>{item.salesChannel}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                                    <Box>
                                                        <img
                                                            src={item.productInformation.productImage}
                                                            className="upload-img"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={9}>
                                                    <Typography>
                                                        {item.productInformation.detail}
                                                    </Typography>
                                                    <Box sx={{ mt: 1 }}>
                                                        <Typography>
                                                            {t("manage_order.jan")} :
                                                            <Typography sx={{ ml: 1 }} component="span">
                                                                {item.productInformation.jan}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ mt: 1 }}>
                                                        <Typography>
                                                            {t("manage_order.quantity")} :
                                                            <Typography sx={{ ml: 1 }} component="span">
                                                                {item.productInformation.quantity}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ mt: 1 }}>
                                                        <Typography>
                                                            {t("manage_order.sub_total")} :
                                                            <Typography sx={{ ml: 1 }} component="span">
                                                                <CurrencyYenOutlinedIcon
                                                                    sx={{
                                                                        fontSize: "12px",
                                                                        top: "2px",
                                                                        position: "relative",
                                                                    }}
                                                                />
                                                                {item.productInformation.subtotal}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} lg={2}>
                                            <Box>
                                                <Typography>
                                                    {t("manage_order.shipping_date_range")} :
                                                </Typography>

                                                <Typography>
                                                    {item.shippingDetail.shippingdate}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: 1 }}>
                                                <Typography>
                                                    {t("manage_order.elapsed_time")} :
                                                </Typography>
                                                <Typography color="primary">
                                                    {item.shippingDetail.elapsedTime}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ mt: 1 }}>
                                                <Typography>
                                                    {t("manage_order.delivery_date")} :
                                                </Typography>
                                                <Typography>
                                                    {item.shippingDetail.deleveryDate}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={2} md={2} lg={2}>
                                            <Grid item lg={12}>
                                                <Button
                                                    className="manage_btn"
                                                    sx={{ mb: 2, display: "block" }}
                                                    variant="contained"
                                                >
                                                    {t("manage_order.i_want_to_pick_up")}
                                                </Button>
                                                <Button className="manage_btn" variant="outlined">
                                                    {t("manage_order.leave_it")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </>
                        );
                    })
                )}
            </div>
        </>
    );
};
export default Return;
