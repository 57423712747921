import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Button,
    Select,
    MenuItem,
    Box,
    FormControl,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import SellerReports from "../services/sellerReports";
import SellerDownload from "../services/sellerDownload";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import * as XLSX from "xlsx";
import SellerReportinfo from "../services/sellerInfoReport";
import TemplateDownloadcsv from "../services/templateDownloadcsv";
import Skeleton from "../../common/Skeleton";
import MUIDataTable from "mui-datatables";
import CONSTANTS, { BULK_PRODUCT_UPLOAD_REPORT_LIST_CONSTANTS, LIST_LIMIT, SHEET_NAME } from "../../../constants/constants";
import { useAppSelector } from "../../../redux/hooks";
import Utility from "../../../utils/Utility";
import DownloadButton from "../../common/DownloadBtn";


const Reports = (props: any) => {

    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const [t, i18n] = useTranslation();
    
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.BULK_PRODUCT_UPLOAD_REPORTS : perPageLimit );
    const { handleLoader,showSnackbar } = props;
    const [reports, setReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [toggle, setToggle] = useState(true);
    const [latestSellerInfo, setLatestSellerInfo] = useState<any>([]);
    const [sellerInfo, setSellerInfo] = useState<any>([]);
    const [appliedFilter, setAppliedFilter] = useState<number>(0);
    const [skeleton, setSkeleton] = useState(true);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [downloadBtnId,setDownloadBtnId]=useState<any>([]);
    const apiData: any[][] = [];
    const storecode = useAppSelector((state) => state?.common?.loggedInStoreCode);


    const filterMenu = [
        t("reports.all_reports"),
        t("reports.completed"),
        t("reports.error"),
    ];

    useEffect(() => {
        latestReportData();
        // SellerReportsData(currentPage);
    }, []);


    const latestReportData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SellerReportinfo(payload, isHitDummyURL)
            .LatestSellerReportsData()
            .then((res) => {
                setLimit(limit);
                setSkeleton(false); 
                setLatestSellerInfo(res);
            })
            .catch((err: any) =>showSnackbar(err?.message, false));
    };

    const ReportsData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SellerReports(payload, isHitDummyURL)
            .sellerReportsData()
            .then((res: any) => setReports(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };
    /* Seller api function*/

    /* download api function*/

    const DownloadsData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SellerDownload(payload, isHitDummyURL)
            .sellerDownloadData()
            .then((res) => setReports(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };
    const CsvResponse = (id: number) => {
        const tempDownloadBtnIdArr =[...downloadBtnId];
        tempDownloadBtnIdArr.push(id);
        setDownloadBtnId(tempDownloadBtnIdArr);
        // handleLoader(true);
        const payload = {};
        const isHitDummyURL = false;
        new TemplateDownloadcsv(payload, isHitDummyURL)
            .dowloadErrorReport(id)
            .then((res: any) => {
                // downloadFile(res?.data,id);    
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                window.open(res.data);        
                // handleLoader(false);
                // handleOnExport(res.xlData);              
            })
            .catch((err: any) => {
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                showSnackbar(err?.message, false);
            });
    };


    /* download api function*/


    useEffect(() => {
        SellerReportsData(firstPage, limit);
    }, [appliedFilter]);

    /*seller info reports function*/

    const SellerReportsData = (currentPage: any, limit: any) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new SellerReportinfo(payload, isHitDummyURL)
            .SellerReportsData(currentPage, limit, appliedFilter)
            .then(
                (res) => {
                    setCurrentPage(currentPage);
                    setLimit(limit);
                    setSkeleton(false),
                    setSellerInfo(res),
                    setTotalCount(res?.pagination?.totalCount);
                    setTotalPages(res?.pagination?.totalPages);
                }
            )
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
        apiTableData();
    };

    // const handleFilterChange = (filter: number) => {
    //     setAppliedFilter(filter);
    //     SellerReportsData(currentPage, limit, filter);
    // };

    const handleFilterChange = (e: any) => {
        const { value } = e.target;
        setAppliedFilter(Number(value));
        
        // SellerReportsData(currentPage, limit,  value);
    };

    const columns = [
        {
            name: t("reports.update_date_time"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.file_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        // {
        //     name: t("reports.batch_id"),
        //     options: {
        //         filter: true,
        //         sort: false,
        //     },
        // },
        {
            name: t("reports.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.action"),
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const actionButton = (id: number, productFailedCount: boolean, status: number) => {
        return (
            <>
                <Box>
                    <DownloadButton loading={downloadBtnId.includes(id)} route={window.location.pathname} functionToExecute={() => CsvResponse(id)} className="table_btn" btnName={t("reports.download")} disableCondition={downloadBtnId.includes(id) ? true : status == BULK_PRODUCT_UPLOAD_REPORT_LIST_CONSTANTS.STATUS_PENDING ? true : false || productFailedCount ? false : true}/>
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        onClick={() => CsvResponse(id)}
                        disabled={downloadBtnId.includes(id) ? true : status == BULK_PRODUCT_UPLOAD_REPORT_LIST_CONSTANTS.STATUS_PENDING ? true : false || productFailedCount ? false : true}
                    >
                        {t("reports.download")}{downloadBtnId.includes(id)  && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button> */}
                </Box>
            </>
        );
    };



    const apiTableData = () => {
        {
            sellerInfo?.sellerReports?.map((item: any, index: number) => {
                const status = item.status==2 ?t("reports.failed") :item.status==0 ?t("reports.in_progress") :
                    item.productFailedCount == 0
                        ? t("reports.completed")
                        : t("reports.error");
                apiData[index] = [];
                apiData[index].push(
                    item.uploadDateTime,
                    item.fileName,
                    // item.batchId,
                    status,
                    actionButton(item.id, item.productFailedCount, item.status)
                );
            });
        }
    };
    apiTableData();

    return (
        <>
            <Container sx={{ my: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.totalCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.total_product")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.productApproved)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.updated_products")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.errorCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.errors_found")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={0} sx={{ mb: 5 }}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={0}
                        md={3}
                        lg={8}
                        sx={{
                            px: 2,
                            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"> {t("reports.filter_by_status")} </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={appliedFilter}
                                label={t("reports.filter_by_status")}
                                size="small"
                                onChange={(e) =>handleFilterChange(e)}>
                                {filterMenu?.map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            <Container className="report_table">
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <div className="main">
                        <MUIDataTable
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t(
                                    "table_text.search_placeholder"
                                ).toString(),
                                responsive: "standard",
                            }}
                        />
                    </div>
                )}
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={SellerReportsData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default Reports;
