import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Typography,  Popover } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TaskIcon from "@mui/icons-material/Task";
import HeaderTitle from "../common/HeaderTitle";
import TemplateDownload from "./TemplateDownload";
import FileUpload from "./FileUpload";
import Reports from "./Reports";
import ManageInventory from "./ManageInventory";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useTranslation } from "react-i18next";


const InventoryManage = (props: any) => {

    const [t] = useTranslation();
    const { handleLoader, showSnackbar } = props;
    const [value, setValue] = React.useState("1");
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElPopover, setAnchorElPopover] = useState<HTMLButtonElement | null>(null);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleLiveImagesCloseModal =()=>{
        setAnchorEl(null);
    };
    const handleInsPopoverOpen = (event: any) => {
        setAnchorElPopover(event.currentTarget);
    };
    
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    return (
        <>
            <Popover
                open={Boolean(anchorElPopover)}
                anchorEl={anchorElPopover}
                onClose={handleInsPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                className="instruction_popover">

                <Box className="instruction_popover_box">
                    <Typography sx={{pb:2}}>{t("bulk_product_index.instruction_no_1")}</Typography>
                </Box>
            </Popover>

            <Popover
                onClose={handleLiveImagesCloseModal}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                className="Image_upload_popover">
           
            </Popover>

            <div className="main">
                <Container sx={{ py: 2 }}>
                    <Grid container spacing={0} sx={{ py: 5 }} className="image-upload-search heading_grid">
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <HeaderTitle  title={t("bulk_product_index.inventory_list")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box component="span" className="image_upload_instruction_icon_box">
                                <Typography className="about_uploading_image">{t("bulk_product_index.outer_text")}
                                    <InfoRoundedIcon                                         
                                        onClick={handleInsPopoverOpen} 
                                        color="primary" 
                                        className="image_upload_instruction_icon"/>
                                </Typography>
                            </Box> 
                        </Grid>                  
                    </Grid>
                    
                    
                    <Box  sx={{  maxWidth: { xs: "600px", sm: "100%", md: "100%", lg: "100%" },}}>
                        <TabContext value={value}>
                            <Container sx={{ py: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box>
                                            <TabList
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab
                                                    icon={<ListAltIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.manage_inventory")}
                                                    value="1"
                                                    color="primary"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<CloudUploadIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.file_upload")}
                                                    value="3"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<TaskIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.reports")}
                                                    value="4"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                            </TabList>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                            
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <ManageInventory handleLoader={handleLoader} showSnackbar={showSnackbar} />
                            </TabPanel>
                            
                            <TabPanel value="3" sx={{ p: 0 }}>
                                <FileUpload
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>

                            <TabPanel value="4" sx={{ p: 0 }}>
                                <Reports
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default InventoryManage;
