import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Grid,
    MenuItem,
    Menu,
    Select,
    Typography,
    Divider,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LanguageIcon from "@mui/icons-material/Language";
import MailIcon from "@mui/icons-material/Mail";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreIcon from "@mui/icons-material/MoreVert";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../Auth/services/AuthService";
import SideNavigation from "../SideNavigation";
import { Language } from "../../../i18n";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import CONSTANTS from "../../../constants/constants";
import { ROUTES } from "../../../constants/routes";
import UploadBox from "../UploadBox";
import UploadBoxBulkImageUpload from "../UploadBoxBulkImageUpload";
import UploadBoxInventory from "../UploadBoxInventory";
import useRefreshBlocker from "../../../utils/refresblockUtil";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Cookies from "../../../utils/Cookies";
import ModalUtility from "../../../utils/modalUtility";
import NotificationMessage from "../Notification";

export default function PrimarySearchAppBar(props: any) {
    const { logout,newSellerLogin ,setNewSellerLogin,setIsMsgRequired , isMsgRequired,  msgVisibility, setMsgVisibility, isCookiePresent} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [langAnchor, setLangAnchor] = React.useState<null | HTMLElement>(null);
    const [settingsAnchorE1, setSettingsAnchorE1] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
    // const [searchValue, setSearchValue] = React.useState("Search...");
    const isMenuOpen = Boolean(anchorEl);
    const isSettingsMenuOpen = Boolean(settingsAnchorE1);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const loggedInUserType = useAppSelector((state) => state.permission.permissionDetails.userType) ?? null;
    const userType = useAppSelector(state => state.permission.permissionDetails.userType) ?? null;
    const dataAfterUpload = useAppSelector( (state:any) => state?.common?.productData) ?? [];

    const dispatch = useAppDispatch();

    const name = useAppSelector((state) => state.common.loggedInUserName) ?? "";
    const isMaintenance = useAppSelector((state) => state.common.isMaintenance) ?? "false";
    const storecode = useAppSelector((state) => state?.common?.loggedInStoreCode) ?? null;
    const storename = useAppSelector((state) => state?.common?.loggedInStoreName) ?? "";
    const adminName = useAppSelector((state) => state?.common?.loggedInAdminUserName) ?? "";
    const isOpenProgressbar = useAppSelector((state) => state.common.isOpenProgressbar) ?? false;
    const isOpenProgressbarInventory = useAppSelector((state) => state.common.isOpenProgressbarInventory) ?? false;
    const isOpenProgressbarBulkImageUpload = useAppSelector((state) => state.common.isOpenProgressbarBulkImageUpload) ?? false;
    

    useEffect(()=>{
        if(window.name=="tab 1"){
            setNewSellerLogin(true);
        }
    },[]);


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [lang, setLang] = React.useState<string | HTMLElement>(i18n.language);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSettingsAnchorE1(event.currentTarget);
    };

    const navigateEditProfile = () => {
        navigate(ROUTES.EXHIBITION_USER_DETAIL);
        handleSettingsMenuClose();
    };
    const navigateProfile = () => {
        dispatch(commonActions.IS_TAB_URL("1"));
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
        // dispatch(commonActions.CANCELLED_FILTER_REMEMBER({}));
        // dispatch(commonActions.SHIPPED_FILTER_REMEMBER({}));
        // dispatch(commonActions.PREPARING_SHIPMENT_FILTER_REMEMBER({}));
        dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
        navigate(ROUTES.PROFILE);
        handleSettingsMenuClose();
    };
    const navigateEditProfileForNonExhibitor = () => {
        dispatch(commonActions.IS_TAB_URL("1"));
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
        // dispatch(commonActions.CANCELLED_FILTER_REMEMBER({}));
        // dispatch(commonActions.SHIPPED_FILTER_REMEMBER({}));
        // dispatch(commonActions.PREPARING_SHIPMENT_FILTER_REMEMBER({}));
        dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
        navigate(ROUTES.STORE_DETAILS);
        handleSettingsMenuClose();
    };

    const handleSettingNavigate = () => {
        navigate(ROUTES.PROFILE);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLangMenuClose = () => {
        setLangAnchor(null);
        handleMobileMenuClose();
    };

    const handleMyAccount = () => {
        handleMenuClose();
    };
    const handlepProfile = () => {
        handleMenuClose();
        navigate(ROUTES.PROFILE);
    };

    const handleChangePassword = () => {
        handleMenuClose();
        navigate(ROUTES.VERIFY_PASSWORD);
    };

    const handleSettingsMenuClose = () => {
        setSettingsAnchorE1(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    useEffect(()=>{
        handleOms();
    },[]);

    const handleOms = () => {
        if(window.location.pathname !== ROUTES.OMS && window.location.pathname !== ROUTES.PRINT_PACKING_SLIP){
            localStorage.setItem("panel","1,2,3,4");
        }
        if(window.location.pathname != ROUTES.OMS && !window.location.pathname.includes("oms-confirm-shipment") && !window.location.pathname.includes("refund-order")){
            dispatch(commonActions.IS_UNSHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_SHIPPED({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_CANCEL({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_REFUND({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
        }
    };

    const reloadPage = useAppSelector((state) => state.common.isReloadPage) ?? false;

    // useRefreshStop(reloadPage);

    const { blockRefresh, setBlockRefresh, userDecision, handleCancelDecision, handleBeforeUnloadDecision } = useRefreshBlocker();
    
    useEffect(() => {
        dispatch(commonActions.IS_RELOAD(false));
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(false));
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_BULK_IMAGE_UPLOAD(false));
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(false));
        if (userDecision === "reload") {
            dispatch(commonActions.IS_RELOAD(true));
            isOpenProgressbar ? dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(true)) : "" ;
            isOpenProgressbarInventory ? dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(true)) : "" ;
            isOpenProgressbarBulkImageUpload ? dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_BULK_IMAGE_UPLOAD(true)) : "" ;
            setBlockRefresh(reloadPage);
            

        } else if (userDecision === "cancel") {
            // setBlockRefresh(reloadPage);
            
        }
    }, [userDecision]);


    const handleLogout = () => {
        if(dataAfterUpload?.length>0){
            const responseMessage = t("imageupload.Batch_reflection_button_has_not_been_clicked");
            const response = confirm(responseMessage);
            if (!response) {
                console.log("Cancelled");
            }else{
                dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
                dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));       
                dispatch(commonActions.IS_TAB_URL("1"));
                dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
                // dispatch(commonActions.CANCELLED_FILTER_REMEMBER({}));
                // dispatch(commonActions.SHIPPED_FILTER_REMEMBER({}));
                // dispatch(commonActions.PREPARING_SHIPMENT_FILTER_REMEMBER({}));
                dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
                if(loggedInUserType == CONSTANTS.USER_TYPE_ADMIN) {
                    handleMenuClose();
                    new AuthService().logout();
                    logout();
                } else {
                    handleMenuClose();
                    new AuthService().logout();
                    logout();
                }
                new Cookies("access-token").delete();
            }
        }else{
           
            dispatch(commonActions.IS_PRODUCT_UPLOAD([]));
            dispatch(commonActions.PRODUCT_UPLOAD_JSON([]));
            dispatch(commonActions.IS_TAB_URL("1"));
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            // dispatch(commonActions.CANCELLED_FILTER_REMEMBER({}));
            // dispatch(commonActions.SHIPPED_FILTER_REMEMBER({}));
            // dispatch(commonActions.PREPARING_SHIPMENT_FILTER_REMEMBER({}));    
            dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_SHIPPED({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_CANCEL({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_REFUND({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({currentPage: 1,limit: 15}));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));

            if(reloadPage==true){
                window.removeEventListener("beforeunload", handleBeforeUnloadDecision);
                const response = confirm("Are you sure you want to exit without save the data?");
                if (!response) {
                    console.log("Cancelled");
                } else {
                    if(loggedInUserType == CONSTANTS.USER_TYPE_ADMIN) {
                        handleMenuClose();
                        new AuthService().logout();
                        logout();
                    } else {
                        handleMenuClose();
                        new AuthService().logout();
                        logout();
                    }
                    new Cookies("access-token").delete();
                }
            } else {
                if(loggedInUserType == CONSTANTS.USER_TYPE_ADMIN) {
                    handleMenuClose();
                    new AuthService().logout();
                    logout();
                } else {
                    handleMenuClose();
                    new AuthService().logout();
                    logout();
                }
            }
        }
    };

    const menuId = "primary-search-account-menu";

    // -----------Profile Menu------
    const renderMenu = (
        <Menu
            className="menu_margin"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Box className="menu_width">
                <Box className="name_menu">
                    <Typography variant="h6" className="menu_item display_block">
                        {loggedInUserType == CONSTANTS.USER_TYPE_ADMIN ? adminName : name}
                    </Typography>
                </Box>

                <Divider />
                {/* {loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? (
                    <>
                        <MenuItem onClick={handleChangePassword}>
                            {t("header.change_password")}
                        </MenuItem>
                        <Divider />
                    </>
                ) : null} */}
                {/* <MenuItem onClick={navigateEditProfile}>Edit Profile</MenuItem> */}
                <MenuItem onClick={handleLogout}>{t("header.log_out")}</MenuItem>
            </Box>
        </Menu>
    );



    const settingMenuID = "settings-menu";
    const renderSettingsMenu = (
        <Menu
            className="menu_margin"
            anchorEl={settingsAnchorE1}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={settingMenuID}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isSettingsMenuOpen}
            onClose={handleSettingsMenuClose}
        >
            {/* <MenuItem onClick={handleUpdatePassword}>
                {t("header.update_password")}
            </MenuItem> */}
            {loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? (
                <MenuItem onClick={navigateEditProfile}>
                    {t("header.edit_profile")}
                </MenuItem>
            ) : (
                <div>
                    <MenuItem onClick={navigateProfile}>
                        {t("header.store_details")}
                    </MenuItem>
                    <MenuItem onClick={navigateEditProfileForNonExhibitor}>
                        {t("header.edit_store_information")}
                    </MenuItem>
                </div>
            )}
        </Menu>
    );

    // -----------Login Menu---------
    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <MailIcon />
                </IconButton>
                <p>{t("header.message")}</p>
            </MenuItem> */}
            <MenuItem
                onClick={
                    loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR
                        ? handleSettingsMenuOpen
                        : handleSettingNavigate
                }
            >
                <IconButton size="large" color="inherit">
                    <SettingsIcon />
                </IconButton>
                <p>{t("side_menu.setting")}</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>{t("header.profile")}</p>
            </MenuItem>
            {/* <MenuItem>
                <IconButton size="large" color="inherit">
                    <QuestionMarkIcon />
                </IconButton>
                <p>{t("header.help")}</p>
            </MenuItem> */}
            <MenuItem>
                <IconButton
                    size="large"
                    aria-haspopup="true"
                    color="inherit"
                    sx={{ mr: -1.5 }}
                >
                    <LanguageIcon />
                </IconButton>
                <Select
                    sx={{
                        "& > fieldset": { border: "none" },
                        color: "black",
                        "& .MuiSvgIcon-root": {
                            color: "black",
                        },
                    }}
                    value={lang}
                    onChange={(e) => handleLanguageChange(e.target.value as string)}
                >
                    {Language.map((item, index) => {
                        return (
                            <MenuItem
                                selected={item.value == lang ? true : false}
                                value={item.value}
                                key={index}
                            >
                                {item.key}
                            </MenuItem>
                        );
                    })}
                </Select>
            </MenuItem>
        </Menu>
    );

    useEffect(()=>{
        setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));  
        setMsgVisibility(localStorage.getItem("systemUpdateNotificationVisibility"));

        const intervalId = setInterval(() => {
            setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));   
            setMsgVisibility(localStorage.getItem("systemUpdateNotificationVisibility"));
        }, 1000);

        setTimeout(()=>{
            clearInterval(intervalId);        
        },10000);

        return () => clearInterval(intervalId);

    },[window.location.pathname, localStorage.getItem("systemUpdateNotification"), localStorage.getItem("systemUpdateNotificationVisibility")]);

    const handleLanguageChange = (lang: string) => {
        setLang(lang);
        i18n.changeLanguage(lang);
        handleLangMenuClose();
    };
    
    return (
    
        <>
            <Box  className={`cz_header ${isMsgRequired ? "header-margin-bottom" :""}`}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: "#00684D" }}>

                        {/* Sidebar Navigation  */}
                        { (userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && isMaintenance == "true" ||isMaintenance == "false" )  ? (<IconButton
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                        >
                        
                            <SideNavigation /> 
                         
                           
                        </IconButton>) : null}
                        
                       
                        {/* Logo  */}
                        <Link to={(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && isMaintenance == "true" ||isMaintenance == "false" )? ROUTES.OMS : ROUTES.UNDER_MAINTENANCE }>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="redirect home"
                                sx={{ mr: 2 }}
                            >
                                <Grid sx={{ display: { xs: "", md: "flex" } }}>
                                    <img src={imagePath("logo.png")} />
                                </Grid>
                            </IconButton>
                        </Link>
                        {(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN && isMaintenance == "true" ||isMaintenance == "false" ) ? (<>
                            {/* Store code  */}
                            <Box className="name_menu header_store " sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block", } }}>
                                <Grid container alignItems="center">
                                    <Typography className="ellipsis">{storename}</Typography>
                                    <Divider className="divider_margin" orientation="vertical" flexItem />
                                    <Typography>( {storecode} )</Typography>
                                </Grid>
                            </Box>
                            
                            <Box sx={{ flexGrow: 1 }} />

                            {/* Right side  Icons */}
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                           
                                {/* <IconButton
                                    size="large"
                                    aria-label="show 4 new mails"
                                    color="inherit"
                                >
                                    <MailIcon />
                                </IconButton> */}

                        
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-haspopup="true"
                             
                                    onClick={loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR
                                        ? handleSettingsMenuOpen
                                        : handleSettingsMenuOpen}
                                >
                                    <SettingsIcon />
                                </IconButton>

                        
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>

                                {/*                         
                                <IconButton size="large" edge="end" color="inherit">
                                    <QuestionMarkIcon />
                                </IconButton> */}

                         
                                <IconButton size="small" color="inherit" aria-haspopup="true">
                                    <Select
                                        sx={{
                                            "& > fieldset": { border: "none" },
                                            color: "white",
                                            "& .MuiSvgIcon-root": {
                                                color: "white",
                                            },
                                        }}
                                        value={lang}
                                        onChange={(e) => handleLanguageChange(e.target.value as string)}
                                    >
                                        {Language.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    selected={item.value == lang ? true : false}
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </IconButton>
                            </Box>
                            {/* -----Mobile Menu Icon----- */}
                            <Box sx={{ display: { xs: "flex", md: "none" } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>  
                        </>): null}
                
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                {renderSettingsMenu}
                {/* {renderSettingsMenu} */}
            </Box>
            {isMsgRequired ? <NotificationMessage isCookiePresent={isCookiePresent} isMsgRequired={isMsgRequired} msgVisibility={msgVisibility}/> : "" }
           
            <UploadBox />
            <UploadBoxBulkImageUpload/>
            <UploadBoxInventory/>
            {newSellerLogin ? 
                <ModalUtility msg={t("header.you_logged_out_from_this_store")} modalOpen={newSellerLogin} isReloadNeeded={true}/>
                :""
            }
           
        </>
    );

}

