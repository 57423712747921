import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
class InventoryDownload {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    then: any;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

   
    async inventoryDownloadData(id: any) {

  
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.INVENTORY_DOWNLOAD}${id}`, this.payload, this.headers).post();
        let downloadCsvFile: string;
        if(response.data.success) {
            downloadCsvFile = response.data.data;
            return downloadCsvFile;
        }
        else {
            throw new Error(response.data.message);
        }
        // return downloadCsvFile;

    }
}

export default InventoryDownload;
